import React, { useEffect, useState } from "react";
import {
  useDispatch, useSelector
} from "react-redux";

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  CardHeader, Input, Label,
  Modal, ModalHeader, ModalBody,
  Button,
} from "reactstrap";
import TableLoader from "components/Common/TableLoader";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { MetaTags } from "react-meta-tags";
import { useTranslation } from "react-i18next";
import { fetchAssetsStart } from "store/assests/actions";
import {
  addNewAddress, fetchAddressBalance, fetchMasterWallet, getAddresses,
  withdrawFromAddress
} from "apis/address";
import CustomPagination from "components/Common/CustomPagination";
import FeatherIcon from "feather-icons-react";
import useModal from "hooks/useModal";
import CustomSelect from "components/Common/CustomSelect";
import { showErrorNotification, showSuccessNotification } from "store/actions";
import { max } from "lodash";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function MasterAddress() {
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState({});
  const fetchWallet = () => {
    setLoading(true);
    fetchMasterWallet().then((response) => {
      if (response.isSuccess) {
        console.log(response);
        setWallet(response.result);
      } else {
        throw new Error(response.message);
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchWallet();
  }, []);
  

  if (!wallet.address) {
    return (
      <Row>
        <Col md={12} className="text-center">
          <AddAddress isMaster />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col md={4} className="text-center">
        <span>
          <h5>Master Address</h5>
        </span>
        <div>
          <p>
            Current Balance: 0.00000000
          </p>
        </div>
      </Col>
      <Col md={4} className="text-center">
        <span>
          <h5>Master Address</h5>
          <div>
            <p>
              {wallet.address
                ? wallet.address
                : "No Address Found"
              }
            </p>
          </div>
        </span>
      </Col>
      <Col md={4} className="text-center">
        <div>
          <Button color="primary" size="lg">Withdraw</Button>
        </div>
      </Col>
    </Row>
  );
}

function AddressWithdrawal(props) {
  const { show, toggle, address } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [hasEnoughBalance, setHasEnoughBalance] = useState(false);
  const [addressDetails, setAddressDetails] = useState(null);
  const [trxBalance, setTrxBalance] = useState(0);
  const [usdtBalance, setUsdtBalance] = useState(0);
  useEffect(() => {
    if (show) {
    } else {
      setAddressDetails(false);
      setLoading(false);
      setHasEnoughBalance(false);
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      const { balance, tokenBalances } = addressDetails || {};
      const trxBalance = balance?.includes("TRX") ? balance?.split(" ")[0] : 0;
      const usdtBalance = tokenBalances?.find((token) => token?.balance?.includes("USDT"))?.balance?.split(" ")[0] || 0;
      setTrxBalance(trxBalance);
      setUsdtBalance(usdtBalance);
      if (Number(trxBalance) >= 40) {
        setHasEnoughBalance(true);
      } else {
        setHasEnoughBalance(false);
      }
    }
  }, [addressDetails]);

  if (!show) {
    return <></>;
  }

  const fetchBalance = () => {
    setLoading(true);
    fetchAddressBalance(address.address).then((response) => {
      if (response.isSuccess) {
        console.log(response);
        setAddressDetails(response.result);
      } else {
        throw new Error(response.message);
      }
    }).catch((error) => {
      dispatch(showErrorNotification(error?.message || "Failed to fetch balance"));
      setHasEnoughBalance(false);
      setAddressDetails(null);
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleWithdraw = (e, v) => {
    setLoading(true);
    withdrawFromAddress({
      fromAddress: address.address,
      toAddress: v.toAddress,
      amount: parseFloat(v.amount)
    }).then((response) => {
      if (response.isSuccess) {
        dispatch(showSuccessNotification("Withdrawal has been submitted successfully"));
      } else {
        throw new Error(response.message);
      }
    }).catch((error) => {
      dispatch(showErrorNotification(error?.message || "Failed to withdraw"));
    }).finally(() => {
      setLoading(false);
      toggle();
    });
  };


  return (
    <>
      <Modal isOpen={show} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle} tag="h4">
          {t("Withdrawal")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              handleWithdraw(e, v);
            }}
          >
            {addressDetails && (
              <Row>
                <Col md={12} className="text-center">
                  <>Balance: {trxBalance} TRX | {usdtBalance} USDT</>
                </Col>
              </Row>
            )}
            <div className="mb-3">
              <Label className="form-label" for="address">
                {t("From Address")}
              </Label>
              <AvField
                name="fromAddress"
                placeholder={t("Enter Address")}
                type="text"
                errorMessage={t("Enter Address")}
                validate={{ required: { value: true } }}
                value={address.address}
                disabled
              />
            </div>
            <div className="mb-3">
              <Label className="form-label" for="address">
                {t("To Address")}
              </Label>
              <AvField
                name="toAddress"
                placeholder={t("Enter Address")}
                type="text"
                errorMessage={t("Enter Address")}
                validate={{ required: { value: true } }}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label" for="amount">
                {t("Amount in USDT")}
              </Label>
              <AvField
                name="amount"
                placeholder={t("Enter Amount in USDT")}
                type="number"
                errorMessage={t("Enter Amount in USDT")}
                validate={{
                  required: { value: true },
                  min: {
                    value: 40,
                    errorMessage: "Minimum amount is 60 USDT"
                  },
                  max: {
                    value: usdtBalance,
                    errorMessage: `You have only ${usdtBalance} USDT in your account`
                  }
                }}
              />
            </div>
            <div>
              
            </div>
            <div className='text-center'>
              {
                loading
                  ?
                  <div className="d-flex justify-content-center">
                    <TableLoader />
                  </div>
                  :
                  <>
                    {
                      !addressDetails ? <>
                        <Button type="button" color="primary" className="" onClick={fetchBalance} disabled={loading}>
                          {t("Fetch Balance")}
                        </Button>
                        
                      </> : 
                        <>
                          {
                            hasEnoughBalance ?
                              <Button type="submit" color="primary" className="" disabled={loading}>
                                {t("Withdraw")}
                              </Button>
                              :
                              <div className="alert alert-danger">
                                {t("Insufficient Balance. You need to have at least 40 TRX for withdrawal.")}
                              </div>
                          }
                        </>
                    }
                  </>
              }
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
}

function AddAddress({
  isMaster = false
}) {
  const [loading, setLoading] = useState(false);
  const [show, toggle] = useModal();
  const { t } = useTranslation();
  const [selectedAsset, setSelectedAsset] = useState();
  const [selectedAddress, setSelectedAddress] = useState();
  const { assets = [] } = useSelector((state) => state.assetReducer);
  const assetOptions = assets.filter(
    (asset) => asset.isCrypto && asset.symbol === "USDT"
  ).map((asset) => {
    // value is Id which is sent to the server 
    // label is the asset's name which the user can see
    return ({
      value: asset,
      label: asset.name
    });
  });

  const dispatch = useDispatch();

  const assetChangeHandler = (selectedAsset) => {
    setSelectedAsset(selectedAsset);
  };
  const networkChangeHandler = (selectedAddress) => {
    setSelectedAddress(selectedAddress);
  };

  const handleAdd = (e, v) => {
    setLoading(true);
    addNewAddress({
      assetId: selectedAsset.value.id,
      chainId: selectedAddress.value,
      isMaster,
      ttl: isMaster ? 0 : Number(v.ttl),
    }).then((response) => {
      if (response.isSuccess) {
        dispatch(showSuccessNotification("Address is being processed. Please wait for a while."));
      } else {
        throw new Error(response.message);
      }
    }).catch((error) => {
      console.log(error);
      dispatch(showErrorNotification(error?.message || "Failed to add address"));
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    return () => {
      setLoading(false);
      setSelectedAddress(null);
      setSelectedAsset(null);
    };
  }, []);

  useEffect(() => {
    setSelectedAddress(null);
  }, [selectedAsset]);

  return (
    <>
      <Button
        color="primary"
        onClick={toggle}
      >
        {t(`Add New ${isMaster ? "Master" : "Address"} Address`)}
      </Button>
      <Modal isOpen={show} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle} tag="h4">
          {t(`Add New ${isMaster ? "Master" : "Address"} Address`)}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              handleAdd(e, v);
            }}
          >
            <div className="mb-3">
              <Label className="form-label" for="address">
                {t("Asset")}
              </Label>
              <CustomSelect
                value={selectedAsset}
                options={assetOptions}
                onChange={assetChangeHandler} 
              />
              <AvField 
                name="asset"
                placeholder={t("Select Asset")}
                type="text"
                errorMessage={t("Select Asset")}
                validate={{ required: { value: true } }}
                value={selectedAsset}
                style={{
                  opacity: 0,
                  height: 0,
                  margin: -10
                }}
              />
            </div>
            {
              selectedAsset?.value && (
                <div className="mb-3">
                  <Label className="form-label" for="address">
                    {t("Address")}
                  </Label>
                  <CustomSelect
                    value={selectedAddress}
                    options={selectedAsset?.value?.networks?.filter(
                      (network) => network.name?.toLowerCase() === "tron"
                    )?.map((network) => ({
                      value: network.chainId,
                      label: network.name
                    }))}
                    onChange={networkChangeHandler} 
                  />
                  <AvField 
                    name="chainId"
                    placeholder={t("Select Asset")}
                    type="text"
                    errorMessage={t("Select Asset")}
                    validate={{ required: { value: true } }}
                    value={selectedAddress}
                    style={{
                      opacity: 0,
                      height: 0,
                      margin: -10
                    }}
                  />
                </div>
              )
            }
            {
              selectedAddress?.value && !isMaster && (
                <div className="mb-3">
                  <AvField
                    name="ttl"
                    label={t("Transaction Duration(in Seconds)")}
                    placeholder={t("Enter Transaction Duration(in Seconds)")}
                    type="number"
                    errorMessage={t("Enter Transaction Duration(in Seconds)")}
                    validate={{ required: { value: true } }}
                  />
                </div>
              )
            }
            <div className='text-center'>
              {
                loading
                  ?
                  <div className="d-flex justify-content-center">
                    <TableLoader />
                  </div>
                  :
                  <Button type="submit" color="primary" className="" disabled={loading}>
                    {t("Add")} 
                  </Button>
              }
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
}

function AddressManagement() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useState(() => {
    dispatch(fetchAssetsStart());
  }, []);

  const [loading, setLoading] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [address, setAddress] = useState(null);
  const [payload, setPayload] = useState({
    docs: [],
    pagination: {},
  });

  const columns = [
    {
      dataField: "recordId",
      text: "ID",
    },
    {
      dataField: "address",
      text: "Address",
      formatter: (address) => (
        <div className="text-center">
          <a href={`https://nile.tronscan.org/#/address/${address.address}`}
            target="_blank" rel="noreferrer"
          >{address.address}</a>
        </div>
      ),
    },
    {
      dataField: "asset",
      text: "Asset",
      formatter: (address) => (
        <div className="text-center">
          {address.assetId.symbol}
        </div>
      ),
    },
    {
      dataField: "network",
      text: "Network",
      formatter: (address) => (
        <div className="text-center">
          {address.chainId.name}
        </div>
      ),
    },
    {
      dataField: "ttl",
      text: "Total Transaction Time(in mins)",
      formatter: (address) => (
        <div className="text-center">
          Approx. {Math.floor(address.ttl / 60)} min
        </div>
      ),
    },
    // {
    //   dataField: "balance",
    //   text: "Balance",
    //   formatter: (address) => (
    //     <div className="text-center">
    //       {address.balance} | {address?.tokenBalances?.map((token) => `${token.balance}`).join(", ") }
    //     </div>
    //   ),
    // },
    {
      dataField: "status",
      text: "Status",
      formatter: (address) => (
        <div className="text-center">
          <Input type="checkbox" id={address.id} switch="none" checked={address.isActive} onChange={() => {

          }} />
          <Label className="me-1" htmlFor={address.id} data-on-label="" data-off-label=""></Label>
        </div>
      ),
    },
    {
      dataField: "withdraw",
      text: "Withdraw",
      formatter: (address) => (
        <div className="text-center">
          <Button color="primary" size="sm" onClick={() => setAddress(address)} >Withdraw</Button>
        </div>
      ),
    }
  ];

  useEffect(() => {
    loadAddresses(1, sizePerPage);
  }, [sizePerPage]);

  const loadAddresses = async (page = 1, limit = 10) => {
    setLoading(true);
    getAddresses({
      page,
      limit,
    }).then((response) => {
      if (response.isSuccess) {
        const { docs, ...rest } = response.result;
        console.log(response);
        setPayload({
          docs,
          pagination: rest,
        });
      }
    }).catch((error) => {
      console.log(error);
      setPayload({
        docs: [],
        pagination: {},
      });
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Address Management
        </title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <h2>Address Management</h2>
          <Row>
            <Col className="col-12">
              <div className="">
                <div className="container-fluid">
                  {/* <>
                    <Col className="col-12">
                      <Card>
                        <CardHeader className="d-flex justify-content-between  align-items-center">
                          <CardTitle className="color-primary">
                            {t("Address Management")}
                          </CardTitle>
                        </CardHeader>
                        <CardBody>
                          <MasterAddress />
                        </CardBody>
                      </Card>
                    </Col>
                  </> */}
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardHeader className="d-flex justify-content-between  align-items-center">
                          <CardTitle className="color-primary">
                            {t("Addresses")} <FeatherIcon
                              icon="refresh-cw"
                              className="icon-lg ms-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => { loadAddresses(1, sizePerPage) }}
                            />
                          </CardTitle>
                          <div className="d-flex flex-row align-items-center justify-content-between">
                            <AddAddress />
                          </div>
                        </CardHeader>
                        <CardBody>
                          <div className="table-rep-plugin">
                            <div
                              className="table-responsive mb-0"
                              data-pattern="priority-columns"
                            >
                              <Table
                                id="tech-companies-1"
                                className="table  table-hover "
                              >
                                <Thead className="text-center table-light" >
                                  <Tr>
                                    {columns.map((column, index) =>
                                      <Th data-priority={index} key={index}>
                                        <span className="color-primary">{column.text}</span>
                                      </Th>
                                    )}
                                  </Tr>
                                </Thead>
                                <Tbody style = {{
                                  fontSize: "12px",
                                  textAlign: "center",
                                  textTransform: "capitalize"
                                }}>
                                  {loading && <TableLoader colSpan={4} />}
                                  {!loading && !payload.docs && <Tr><Td colSpan={6}>No Data Found</Td></Tr>}
                                  {(!loading && payload.docs) && payload.docs.map((row, rowIndex) =>
                                    <Tr key={rowIndex}>
                                      {columns.map((column, index) =>
                                        <Td key={`${rowIndex}-${index}`}>
                                          { column.dataField === "checkbox" ? <input type="checkbox"/> : ""}
                                          { column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                        </Td>
                                      )}
                                    </Tr>
                                  )}
                                </Tbody>                              
                              </Table>
                              <CustomPagination
                                {...payload.pagination}
                                docs={payload.docs}
                                setSizePerPage={setSizePerPage}
                                sizePerPage={sizePerPage}
                                onChange={loadAddresses}
                              />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {
        address && (
          <AddressWithdrawal
            show={!!address}
            toggle={() => setAddress(null)}
            address={address}
          />
        )
      }
    </React.Fragment>
  );
}
export default (AddressManagement);