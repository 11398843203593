import {
  call, put, takeEvery
} from "redux-saga/effects";
import * as clientApi from "apis/bonus";
import {
 
  fetchBonusSuccess,
  fetchBonusFail,


  addBonusSuccess,
  addBonusFail,
  addBonusClear,


  deleteBonusSuccess,
  deleteBonusFail,

  
  editBonusSuccess,
  editBonusClear,
  editBonusFail
} from "./actions";
import {
  FETCH_BONUS_REQUESTED,
  ADD_BONUS_REQUESTED,
  EDIT_BONUS_REQUESTED,
  DELETE_BONUS_REQUESTED
} from "./actionTypes";
import { showSuccessNotification } from "store/notifications/actions";
 
function * fetchBonus(params){
  try {
    const data = yield call(clientApi.getAllBonus, params);    
    yield put (fetchBonusSuccess(data));
  } catch (error){
    yield put(fetchBonusFail({ error: error.message }));
  }
}

function * addBonus(params){
  try {
    const data = yield call(clientApi.postBonus, params);    
    const { result } = data;
    yield put(addBonusSuccess(result));
    yield put(showSuccessNotification("Bonus created successfully"));
    yield put(addBonusClear());
  } catch (error){
    yield put(addBonusFail({ error: error.message }));
  }
}
  
function * deleteBonus(params){
  
  try {
    const data = yield call(clientApi.deleteBonus, params);
    yield put(deleteBonusSuccess(data));
  } catch (error){
    yield put(deleteBonusFail({ error: error.message }));
  }
}
  
function * editBonus(params){
  const { payload } = params;
  try {
    const data = yield call(clientApi.updateBonus, payload);

    yield put(editBonusSuccess(data));

    yield put(showSuccessNotification("Bonus updated successfully"));
    yield put(editBonusClear());
  } catch (error){
    yield put(editBonusFail({ error: error.message }));
  }
}
  
function * bonusSaga() {
  yield takeEvery(FETCH_BONUS_REQUESTED, fetchBonus);
  yield takeEvery(ADD_BONUS_REQUESTED, addBonus);
  yield takeEvery(DELETE_BONUS_REQUESTED, deleteBonus);
  yield takeEvery(EDIT_BONUS_REQUESTED, editBonus);
}
  
export default bonusSaga;