
export const FETCH_PRODUCTS_START = "FETCH_PRODUCTS_START";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_ERROR = "FETCH_PRODUCTS_ERROR";

export const ADD_PRODUCT_START = "ADD_PRODUCT_START";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_ERROR = "ADD_PRODUCT_ERROR";
export const ADD_PRODUCT_CLEAR = "ADD_PRODUCT_CLEAR";

export const EDIT_PRODUCT_START = "EDIT_PRODUCT_START";
export const EDIT_PRODUCT_IMAGE_START = "EDIT_PRODUCT_IMAGE_START";
export const EDIT_PRODUCT_CLEAR = "EDIT_PRODUCT_CLEAR";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_ERROR = "EDIT_PRODUCT_ERROR";

export const TOGGLE_PRODUCT_START = "TOGGLE_PRODUCT_START";
export const TOGGLE_PRODUCT_SUCCESS = "TOGGLE_PRODUCT_SUCCESS";
export const TOGGLE_PRODUCT_ERROR = "TOGGLE_PRODUCT_ERROR";