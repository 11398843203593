import React, {
  useState, useEffect
} from "react";
import { useDispatch, connect } from "react-redux";
import {
  Row, Col, Card, CardBody, CardHeader, CardTitle, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";

import AddDepositForm from "./AddDepositForm";
import {
  fetchMMDeposits, rejectMMDeposit, approveMMDeposit   
} from "store/moneyManagementTransactions/deposits/actions";
import SearchBar from "components/Common/SearchBar";
import CustomPagination from "components/Common/CustomPagination";
import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import TableLoader from "components/Common/TableLoader";
import Notification from "components/Common/Notification";
import logo from "../../../assets/images/logo-sm.svg";
import { withTranslation } from "react-i18next";
import { checkAllBoxes } from "common/utils/checkAllBoxes";
import { Link } from "react-router-dom";
import DetailsModal from "./DetailsModal";
import { captilazeFirstLetter } from "common/utils/manipulateString";

function MMDeposit(props){
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [showNotication, setShowNotifaction] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState("");
  const [sizePerPage, setSizePerPage] = useState(10);
  const [btnprimary1, setBtnprimary1] = useState(false);
  const columns = [
    {
      dataField:"checkbox",
      text: <input type="checkbox" id = "check-all-deposits" onChange = {()=>checkAllBoxes("check-all-deposits", ".deposit-checkbox")}/>
    },
    {
      dataField: "createdAt",
      text: props.t("Date"),
      formatter: (val) => {
        let d = new Date(val.createdAt);
        d = d.getDate()  + "-" + (d.getMonth() +  1) + "-" + d.getFullYear() + " " +
        d.getHours() + ":" + d.getMinutes();
        return d;
      }
    }, 
    {
      dataField:"customerId",
      text:props.t("Client"),
      formatter:(val)=>{
        return (
          <div>
            <Link 
              to ={{
                pathname : `/clients/${val?.customerId?._id}/profile`,
                state : { clientId : val.customerId }
              }}>
              <i className="no-italics">{val.customerId ? `${captilazeFirstLetter(val.customerId.firstName)} ${captilazeFirstLetter(val.customerId.lastName)}` : ""}</i>
            </Link>
          </div>
        );
      }
    },
    {
      dataField: "type",
      text: props.t("Deposit Type"),
      formatter: (val) => (val.type == "WIRE_TRANSFER" ? "Wire" : val.type?.split("_").join(" ")?.toLowerCase())
    },
    {
      dataField:"amount",
      text:props.t("Amount"),
      formatter: (val) => (val?.amount?.$numberDecimal || val?.amount || "-"),
    },
    {
      dataField: "status",
      text: props.t("Status"),
      formatter: (val) => (
        val.status?.toLowerCase()
      )
    },
    {
      dataField: "strategyId",
      text: props.t("Strategy Name"),
      formatter: (val) => (
        val?.strategyId?.name.toLowerCase()
      )
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: props.t("Actions"),
      formatter: (val) => (
        <UncontrolledDropdown className={`"chat-noti-dropdown" ${!props.depositsPermissions ? "d-none" : ""}`} >
          <DropdownToggle tag="i" className="text-muted" style={{ cursor: "pointer" }}>
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem href="#" onClick={()=>{
              depositApprove(val);
            }}>{props.t("Approve")}</DropdownItem>
            <DropdownItem onClick={()=>{
              depositReject(val);
            }} href="#">{props.t("Reject")}</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    },
    // {
    //   dataField: "",
    //   isDummyField: true,
    //   editable: false,
    //   text: props.t("Details"),
    //   formatter: (val) => (
    //     <div className="text-center">
    //       <Link className={val.gateway === "BLOCKCHAIN" ? "text-success" : "text-muted"} to="#">
    //         <i
    //           className="mdi mdi-eye font-size-20"
    //           id="edittooltip"
    //           onClick={() => {
    //             if (val.gateway === "BLOCKCHAIN"){
    //               setDetailsModal(true); 
    //               setSelectedContent(val.rawData);
    //             }
    //           }}
    //         ></i>
    //       </Link>
    //     </div>
    //   ),
    // },
  ];
  
  useEffect(()=>{
    if (!detailsModal)
      loadDeposits();
  }, [sizePerPage, 1, searchInput, props.depositResponseMessage, props.depositChangeStatusSuccess]);
  
  const handleSearchInput = (e)=>{
    setSearchInput(e.target.value);
  };
  
  
  const loadDeposits = ()=>{
    dispatch(fetchMMDeposits());
  };

  const depositApprove = (deposit)=>{
    dispatch(approveMMDeposit({
      id:deposit._id,
      customerId:deposit.customerId._id 
    }));
    // setShowNotifaction(true);
  };

  const depositReject = (deposit)=>{
    dispatch(rejectMMDeposit({
      id:deposit._id,
      customerId:deposit.customerId._id 
    }));
    // setShowNotifaction(true);
  };
  
  const closeNotifaction = () => {
    setShowNotifaction(false);
  };

  return (
    <React.Fragment>
      <Notification
        onClose={closeNotifaction}
        body={props.t("The deposit has been updated successfully")}
        show={showNotication}
        header={props.t("Deposit Update")}
        logo={logo}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardHeader className="d-flex flex-column gap-3 ">
              <div className="d-flex justify-content-between align-items-center">
                
                <CardTitle className="color-primary">{props.t(`Deposits(${props.deposits.length})`)}</CardTitle>
                <AddDepositForm />
              </div>
              
              <div className="d-flex justify-content-between align-items-center">
                <SearchBar handleSearchInput={handleSearchInput} placeholder={props.t("Search for deposits")}/>
                <div>
                  {/* <Dropdown
                    isOpen={btnprimary1}
                    toggle={() => setBtnprimary1(!btnprimary1)}
                  >
                    <DropdownToggle tag="button" className="btn btn-primary">
                      {selected} <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem value="STRATEGY_DEPOSIT" onClick={(e)=>{setSelected(e.target.value)}}>Strategy Deposit</DropdownItem>
                      <DropdownItem value="STRATEGY_PROFIT" onClick={(e)=>{setSelected(e.target.value)}}>Strategy Profit</DropdownItem>
                    </DropdownMenu>
                  </Dropdown> */}
                </div>
              </div>
              
            </CardHeader>
            
            
            <CardBody>
              
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table
                    id="tech-companies-1"
                    className="table  table-hover "
                  >
                    <Thead className="text-center table-light" >
                      <Tr>
                        {columns.map((column, index) =>
                          <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                        )}
                      </Tr>
                    </Thead>
                    {
                      props.deposits.length === 0 
                        ?
                        <Tbody style = {{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize"
                        }}>
                          {props.loading && <TableLoader colSpan={4} />}                            
                          {!props.loading &&
                            <>
                              <Tr>
                                <Td colSpan={"100%"} className="fw-bolder text-center" st>
                                  <h3 className="fw-bolder text-center">No records</h3>
                                </Td>
                              </Tr>
                            </>
                          }
                        </Tbody>
                        :
                        <Tbody style = {{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize"
                        }}>
                          {props.loading && <TableLoader colSpan={4} />}
                          {!props.loading && props.deposits.map((row, rowIndex) =>
                            <Tr key={rowIndex}>
                              {columns.map((column, index) =>
                                <Td key={`${rowIndex}-${index}`} className={`pt-4 ${column.dataField === "dropdown" && "d-flex justify-content-center"}`}>
                                  { column.dataField === "checkbox" ? <input className = "deposit-checkbox" type="checkbox"/> : ""}
                                  { column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                </Td>
                              )}
                            </Tr>
                          )}
                        </Tbody>
                    }
                  </Table>
                  <CustomPagination
                    {...props}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadDeposits}
                    docs={props.deposits}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {<DetailsModal rawData= {selectedContent} open = {detailsModal} onClose = {()=>setDetailsModal(false)} />}
    </React.Fragment>
  );

}

const mapStateToProps = (state) => ({
  loading: state.mmDepositReducer.loading || false,
  deposits: state.mmDepositReducer.mmDeposits || [],
  page: state.mmDepositReducer.page || 1,
  totalDocs: state.mmDepositReducer.totalDocs || 0,
  totalPages: state.mmDepositReducer.totalPages || 0,
  hasNextPage: state.mmDepositReducer.hasNextPage,
  hasPrevPage: state.mmDepositReducer.hasPrevPage,
  limit: state.mmDepositReducer.limit,
  nextPage: state.mmDepositReducer.nextPage,
  pagingCounter: state.mmDepositReducer.pagingCounter,
  prevPage: state.mmDepositReducer.prevPage,
  depositsPermissions : state.Profile.depositsPermissions || {},
  depositResponseMessage:state.mmDepositReducer.mmDepositResponseMessage,
  depositChangeStatusSuccess: state.mmDepositReducer.depositChangeStatusSuccess
});
export default connect(mapStateToProps, null)(withTranslation()(MMDeposit));