import React, {
  useEffect,
  useReducer,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import {
  Button,
  Card, CardBody, CardHeader, CardTitle
} from "reactstrap";
import TableLoader from "components/Common/Loader";
import FeatherIcon from "feather-icons-react";
import usePermissions from "routes/permissions";
import CustomCreatableSelect from "components/Common/CustomCreatableSelect";
import { startCase } from "lodash";
import { fetchFacebookFormsFields, updateFacebookFormsFields } from "store/actions";

function FacebookFormFields() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    facebookFormsFieldsPermissions,
  } = usePermissions();
  const {
    loading,
    fields,
    clearingCounter,
  } = useSelector((state) => ({
    loading: state.facebookFieldsReducer?.loading || false,
    fields: state.facebookFieldsReducer?.fields || {},
    clearingCounter: state.facebookFieldsReducer?.clearingCounter || 0,
  }));

  const loadFacebookFields = () => {
    dispatch(fetchFacebookFormsFields());
  };

  useEffect(() => {
    loadFacebookFields();
  }, [clearingCounter]);

  const [state, dispatchState] = useReducer((state, action) => {
    switch (action.type) {
      case "init":
        return {
          ...state,
          ...action.products,
        };
      case "fullName":
        return {
          ...state,
          fullName: action.fullName,
        };
      case "email":
        return {
          ...state,
          email: action.email,
        };
      case "phone":
        return {
          ...state,
          phone: action.phone,
        };
      case "country":
        return {
          ...state,
          country: action.country,
        };
      case "jobTitle":
        return {
          ...state,
          jobTitle: action.jobTitle,
        };
      default:
        return state;
    }
  }, fields);

  const handleUpdate = () => {
    dispatch(updateFacebookFormsFields({
      ...state,
    }));

  };
  

  useEffect(() => {
    dispatchState({
      type: "init",
      products: fields,
    });
  }, [fields]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t("Facebook Form Fields")}</title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <h2>{t("Facebook Form Fields")}</h2>
          <Card>
            <CardHeader className="d-flex justify-content-between  align-items-center">
              <CardTitle>
                Facebook Form Fields
                <FeatherIcon
                  icon="refresh-cw"
                  className="icon-lg ms-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => { loadFacebookFields() }}
                />
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="mb-4">
                <p>{t("Please select the fields you want to add to the form.")}</p>
                <p>{t("You can add new fields by typing the field name and pressing enter.")}</p>
                <p>{t("You can delete the fields by clicking on the `x` icon.")}</p>
                <p>{t("You need to add the fields exactly as they are used in the form with `_` between each words.")}</p>
                <p>{t("Example:")}</p>
                <code>If your form has Phone Number - Enter phone_number in the field</code>
              </div>
              <div className="mt-4">
                {
                  fields &&
                  Object.keys(fields).map((key, index) => {
                    return (
                      <div
                        className="mb-4"
                        key={index}>
                        <h5>{`${startCase(key)} Field`}</h5>
                        <CustomCreatableSelect
                          isMulti
                          isClearable
                          // disableDeleteButton={!deletePermission}
                          // disableCreateButton={!update}
                          placeholder={t("Please enter the fields you want to add with `_` between words.")}
                          dispatchState={(passedData) => dispatchState({
                            type: key,
                            [key]: passedData,
                          })}
                          value={state?.[key]?.map((item) => {
                            return {
                              value: item,
                              label: item,
                            };
                          })}
                        />
                      </div>
                    );
                  })
                }
                {facebookFormsFieldsPermissions.update && <>
                  <Button
                    color="primary"
                    // className={`d-flex justify-content-end  align-items-center btn btn-primary ${!update ? "d-none" : ""}`}
                    onClick={handleUpdate}
                  >
                    {t("Update Fields")}
                  </Button>
                </>}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}
export default FacebookFormFields;