import {
  FETCH_FACEBOOK_FORMS_FIELDS,
  FETCH_FACEBOOK_FORMS_FIELDS_FAILED,
  FETCH_FACEBOOK_FORMS_FIELDS_SUCCESS,
  UPDATE_FACEBOOK_FORMS_FIELDS,
  UPDATE_FACEBOOK_FORMS_FIELDS_FAILED,
  UPDATE_FACEBOOK_FORMS_FIELDS_SUCCESS,
} from "./actionsType";

export const fetchFacebookFormsFields = () => {
  return {
    type: FETCH_FACEBOOK_FORMS_FIELDS,
  };
};

export const fetchFacebookFormsFieldsSuccess = (payload) => {
  return {
    type: FETCH_FACEBOOK_FORMS_FIELDS_SUCCESS,
    payload,
  };
};

export const fetchFacebookFormsFieldsFailed = () => {
  return {
    type: FETCH_FACEBOOK_FORMS_FIELDS_FAILED,
  };
};

export const updateFacebookFormsFields = (payload = {}) => {
  return {
    type: UPDATE_FACEBOOK_FORMS_FIELDS,
    payload,
  };
};

export const updateFacebookFormsFieldsSuccess = () => {
  return {
    type: UPDATE_FACEBOOK_FORMS_FIELDS_SUCCESS,
  };
};

export const updateFacebookFormsFieldsFailed = () => {
  return {
    type: UPDATE_FACEBOOK_FORMS_FIELDS_FAILED,
  };
};