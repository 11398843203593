import logo from "assets/images/brands/finitic.png";
import lLogo from "assets/images/brands/light-logo.png";

export const clientName = "Finitic";
export const developedBy = "Finitic";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_URL;
export const companyName =  "Finitic";
export const sidebarLogo = logo;
export const mainLogo = logo;
export const lightLogo = lLogo;
