import React, { 
  useState, useEffect, useCallback 
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col,
  Input,
  Alert
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
// import "../SearchableInputStyles.scss";
import { withTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import { fetchDictionaryStart } from "store/dictionary/actions";
import { createLeadAssign } from "store/teams/actions";
import AvFieldSelect from "components/Common/AvFieldSelect";
import SearchableCampaignSelect from "./SearchableCampaignSelect";
import SearchableAgentSelect from "./SearchableAgentSelect";
import SearchableTeamSelect from "./SearchableTeamSelect";

function AddLeadStructure(props){

  const dispatch = useDispatch();
  const [addModal, setAddModal] = useState(false);
  const [team, setTeam] = useState(null);
  const [countriesList, setCountriesList] = useState([]);
  const [isCampaign, setIsCampaign] = useState(null);

  const {
    countries,
    clearingCounter
  } = useSelector((state)=>({
    countries: state.dictionaryReducer.countries || [],
    clearingCounter: state.teamsReducer.clearingCounter || 0
  }));

  const toggle = () => setAddModal(!addModal);

  
  const toggleAddModal = () => {
    setIsCampaign(null);
    toggle();
  };

  const handleAddGroup = (e, v) => {
    if (isCampaign) {
      dispatch(createLeadAssign({
        ...v,
        agent: v.agent?.map((item)=>item.value)
      }));
    } else {
      dispatch(createLeadAssign({
        ...v,
        type: isCampaign ? "campaign" : "team",
        countries: countriesList?.map((item)=>item.value)
      }));
    }
  };

  useEffect(()=>{
    dispatch(fetchDictionaryStart());
  }, []);

  return (
    <React.Fragment >
      <Button color="primary" className={"btn btn-primary "} onClick={toggleAddModal}><i className="bx bx-plus me-1"></i> {props.t("Add New Group")}</Button>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Add New Group")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              handleAddGroup(e, v);
            }}
          >
            <Row className="mb-3">
              <Col md="12">
                <Label>{props.t("Type")}</Label>
                <div>
                  <AvFieldSelect
                    required
                    name="type"
                    options={[
                      {
                        label: "Campaign",
                        value: "campaign"
                      },
                      {
                        label: "Country",
                        value: "country"
                      },
                    ]}
                    onChange={(e) => setIsCampaign(e === "campaign")}
                    classNamePrefix="select2-selection"
                    placeholder = "Choose Type"
                  />
                </div>
              </Col>
            </Row>
            {
              isCampaign === null ? null : !isCampaign ? (<>
                <Row className="mb-3">
                  <Col md="12">
                    <SearchableTeamSelect
                      isRequired
                      onChange={(e) => setTeam(e.value)}
                    />
                  </Col>
                </Row>
                {
                  team && <>{
                    team?.members?.length === 0 ? <Alert color="info" className="mb-4">
                      <div>
                        <h5 className="alert-heading">No Team Members</h5>
                      </div>
                    </Alert> : <>
                      <Row className="mb-3" >
                        <Col md="6">
                          <Label>{props.t("Manager")}</Label>
                          <div>
                            <Input
                              type="text"
                              name="manager"
                              value={`${team.managerId.firstName} ${team.managerId.lastName}`}
                              disabled
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <Label>{props.t("Priority")}</Label>
                          <div>
                            <AvField
                              type="number"
                              name={`manager.priority.${team.managerId._id}`}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please enter a priority",
                                },
                                min: {
                                  value: 0,
                                  errorMessage: "Please enter a valid priority",
                                },
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      {
                        team?.members?.map((member, index) => (
                          <Row className="mb-3" key={index}>
                            <Col md="6">
                              <Label>{props.t("Member")}</Label>
                              <div>
                                <Input
                                  type="text"
                                  name={`member.${member._id}`}
                                  value={`${member.firstName} ${member.lastName}`}
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <Label>{props.t("Priority")}</Label>
                              <div>
                                <AvField
                                  type="number"
                                  name={`member.priority.${member._id}`}
                                  validate={{
                                    required: {
                                      value: true,
                                      message: "Please enter a priority",
                                    },
                                    min: {
                                      value: 1,
                                      message: "Please enter a valid priority",
                                    },
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        ))
                      }
                      <Row className="mb-3">
                        <Label>{props.t("Countries")}</Label>
                        <CreatableSelect
                          isMulti
                          isSearchable
                          isClearable
                          onChange={(newValue) => {
                            setCountriesList(newValue);
                          }}
                          // onInputChange={(newValue) => setInputValue(newValue)}
                          // onKeyDown={handleKeyDown}
                          label={props.t("Countries")}
                          hint={props.t("Countries")}
                          placeholder={props.t("Enter Countries")}
                          noOptionsMessage={() => props.t("No countries found")}
                          value={countriesList}
                          options={countries?.map((country) => ({
                            label: `${country.en} | ${country.ar}`,
                            value: country,
                          }))}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter a country",
                            },
                          }}
                        />
                      </Row>
                      <Row>
                        <Alert color="info" className="mb-4">
                          {props.t("Highest priority will be assigned first and based on the priority the number of leads will be assigned to them.")}
                        </Alert>
                      </Row>
                      <Row>
                        <Button color="primary" className="btn btn-primary" type="submit" disabled={!countriesList.length}>
                          {props.t("Add")}
                        </Button>
                      </Row>
                    </>
                  }</>
                }</>) : (<>
                <Row className="mb-3">
                  <Col md="12">
                    <SearchableCampaignSelect
                      isRequired
                    />
                  </Col>
                  <Col md="12">
                    <SearchableAgentSelect
                      isRequired
                    />
                  </Col>
                </Row>
                <Row>
                  <Button color="primary" className="btn btn-primary" type="submit" >
                    {props.t("Add")}
                  </Button>
                </Row>
              </>)
            }
          </AvForm>
        </ModalBody> 
      </Modal>
    </React.Fragment>
  );
}

export default (withTranslation()(AddLeadStructure));