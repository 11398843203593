
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { 
  useDispatch, connect, useSelector
} from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Modal, Button,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  AvForm,
} from "availity-reactstrap-validation";
import Loader from "components/Common/Loader";
import useModal from "hooks/useModal";
import AvFieldSelect from "components/Common/AvFieldSelect";
import {  fetchBonus } from "store/bonus/actions";
import {createBonusRequest} from "store/requests/actions";
import { fetchAccountTypes } from "store/actions";

const platform = ["MT4", "MT5"];


function ApplyBonus(props) {
  const dispatch = useDispatch();
  const [sizePerPage, setSizePerPage] = useState(10);
  const [showModal, toggleModal] = useModal(false);
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [selectedBonus, setSelectedBonus] = useState();
  const [selectedPlatform, setSelectedPlatform] = useState();


  const tradingAccounts = useSelector((state) => state.tradingAccountReducer.accounts.docs);  
  const create = useSelector((state) => state.Profile?.AccTypesPermissions?.create);
  const {accountTypes, submitting, error } = useSelector((state) => state.tradingAccountReducer);

  useEffect(() => {
    (!submitting && !error && showModal) && toggleModal();
  }, [submitting]);
  useEffect(()=>{loadAccountTypes, loadBonusDetails(1, sizePerPage)}, []);

  const loadAccountTypes = () => {
    dispatch(fetchAccountTypes());
  };
  const loadBonusDetails = (page, limit) => {
    dispatch(fetchBonus({ 
      page,
      limit
    }));
  };

  const handleSubmit = (e, v) => {
    e.preventDefault();

    dispatch(createBonusRequest(
      {
        ...v,
        customerId:props.clientData._id
      }));
  };
  
  return (
    <>
      <Link to="#" className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={toggleModal}> Apply Bonus</Link>
      <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal} tag="h4">
          Apply Bonus
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='px-4'
            onValidSubmit={(e, v) => { handleSubmit(e, v) }}
          >
         
            <AvFieldSelect
              name="bonusId"
              label="Bonus Name"
              placeholder="Select Bonus Name"
              options={props.docs?.filter((type) => type.expiration === "valid").map((type) => ({
                value: type._id,
                label: type.bonusName
              }))}
              value={props.docs}
              onChange={(e) => setSelectedBonus(e)}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Bonus Name is required"
                }
              }}
            />
            {
              <AvFieldSelect
                name="platform"
                label="Platform"
                placeholder="Select Platform Type"
                options={platform.map((type) => ({
                  value: type,
                  label: type
                }))}
                onChange={(e)=>setSelectedPlatform(e) }
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Client Type is required"
                  }
                }}
              />
              
            }
            {/* .filter((type) => "Executive MT4" || "Executive MT5") */}
            <AvFieldSelect
              name="tradingAccountId"
              label="Account Group"
              placeholder="Select Account Group Options"
              options={tradingAccounts
                ?.filter((account) => account.type === "LIVE" && account.platform === selectedPlatform)
                ?.map((account) => ({
                  value: account._id,
                  label: `${account.login} (${account?.accountTypeId?.title})`,
                }))}

              onChange={(e) => {
                setSelectedAccount(e);
              }}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Leverage options is required"
                }
              }}
            />
          
            <div className='text-center mt-3 p-2'>
              {
                submitting
                  ? <Loader />
                  : <Button disabled={props.addLoading} onClick={toggleModal} type="submit" color="primary">
                    Add
                  </Button>
              }
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
}
const mapStateToProps = (state) => ({
  loading: state.bonusReducer.loading || false,
  docs: state.bonusReducer.docs || [],
  page: state.bonusReducer.page || 1,
  totalDocs: state.bonusReducer.totalDocs || 0,
  totalPages: state.bonusReducer.totalPages || 0,
  hasNextPage: state.bonusReducer.hasNextPage,
  hasPrevPage: state.bonusReducer.hasPrevPage,
  limit: state.bonusReducer.limit,
  nextPage: state.bonusReducer.nextPage,
  pagingCounter: state.bonusReducer.pagingCounter,
  prevPage: state.bonusReducer.prevPage,
  clearingCounter: state.bonusReducer.clearingCounter,
  deleteLoading: state.bonusReducer.deleteLoading,
  deleteClearingCounter: state.bonusReducer.deleteClearingCounter,
  addClearingCounter: state.bonusReducer.addClearingCounter,
  editResult: state.bonusReducer.editResult
});
export default connect(mapStateToProps, null)(withTranslation()(ApplyBonus));