import {
  call,
  put,
  takeEvery
} from "redux-saga/effects";
// Login Redux States
import {
  FETCH_PRODUCTS_START,
  ADD_PRODUCT_START,
  EDIT_PRODUCT_START,
  TOGGLE_PRODUCT_START,
  EDIT_PRODUCT_IMAGE_START,
} from "./actionTypes";
import {
  fetchProductSuccess,
  fetchProductError,
  addProductSuccess,
  addProductError,
  addProductClear,
  toggleProductSuccess,
  toggleProductError,
  editProductClear,
} from "./actions";
import {
  showErrorNotification,
  showSuccessNotification,
} from "store/notifications/actions";

//Include Both Helper File with needed methods
import * as goldApi from "../../../apis/gold";

function* fetchProduct(params = {}) {
  try {
    const data = yield call(goldApi.getProduct, params);
    yield put(fetchProductSuccess(data));
  } catch (error) {
    yield put(fetchProductError(error));
  }
}

function* addProduct({ payload: { params } }) {
  try {
    const data = yield call(goldApi.addProduct, params);

    const { result } = data;
    yield put(addProductSuccess(result));
    yield put(showSuccessNotification("Product added successfully"));
    yield put(addProductClear());
  } catch (error) {
    yield put(showErrorNotification(error.message));
    yield put(addProductError(error));
  }
}

function* editProduct(params) {
  try {
    yield call(goldApi.editProduct, params);
    yield put(showSuccessNotification("Product updated successfully"));
    yield put(editProductClear());
  } catch (error) {
    yield put(showErrorNotification(error.message));
  }
}

function* editProductImage(params) {
  try {
    yield call(goldApi.editProductImage, params);
    yield put(showSuccessNotification("Product image updated successfully"));
    yield put(editProductClear());
  } catch (error) {
    yield put(showErrorNotification(error.message));
  }
}


function* toggleProduct({ payload }) {
  try {
    const data = yield call(goldApi.toggleProduct, { id: payload });
    const { result } = data;
    yield put(toggleProductSuccess(result));
    yield put(showSuccessNotification("Product toggled successfully"));
  } catch (error) {
    yield put(toggleProductError({ error: error.message }));
    yield put(showErrorNotification(error.message));
  }
}

function* goldSaga() {
  yield takeEvery(FETCH_PRODUCTS_START, fetchProduct);
  yield takeEvery(ADD_PRODUCT_START, addProduct);
  yield takeEvery(EDIT_PRODUCT_START, editProduct);
  yield takeEvery(EDIT_PRODUCT_IMAGE_START, editProductImage);
  yield takeEvery(TOGGLE_PRODUCT_START, toggleProduct);
}

export default goldSaga;
