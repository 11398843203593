import TableLoader from "components/Common/Loader";
import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import {
  Tbody,
  Thead,
  Tr,
  Table,
  Td,
  Th
} from "react-super-responsive-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
  Spinner,
  UncontrolledDropdown
} from "reactstrap";
import CustomPagination from "components/Common/CustomPagination";
import GoldAddModal from "./ProductAddModal";
import { useDispatch, useSelector } from "react-redux";
import {  fetchProduct, toggleProduct } from "store/actions";
import { useTranslation } from "react-i18next";
import ProductEditModal from "./ProductEditModal";

export default function GoldPrices() {

  const [editModalData, setEditModalData] = React.useState(undefined);

  const removeModal = () => setEditModalData(undefined);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const productsData = useSelector((state) => state.goldReducer.products);
  const goldPermissions = useSelector((state) => state.Profile.goldPermissions);
  const [sizePerPage, setSizePerPage] = React.useState(10);
  const { products, loading, clearingCounter } = productsData;
  useEffect(() => {
    loadProducts(1, sizePerPage);
  }, []);

  useEffect(() => {
    dispatch(fetchProduct());
    if (editModalData) {
      removeModal();
    }
  }, [ clearingCounter]);

  const loadProducts = (page, limit) =>{
    dispatch(fetchProduct({
      page,
      limit
    }));
  };

  const columns = [
    {
      text: "Added in DB",
      dataField: "db",
      formatter: (item) => <>{item?.createdAt?.split("T")[0]}</>,
    },
    {
      text: "Last Updated",
      dataField: "db",
      formatter: (item) => <>{item?.updatedAt?.split("T")[0]}</>,
    },
    {
      text: "Order/Priority - (Higher to Lower)",
      dataField: "order",
      sort: true,
    },
    {
      text: "Title",
      dataField: "title",
      sort: true,
    },
    {
      text: "Type",
      dataField: "symbol",
      sort: true,
      formatter: (item) => <>{item?.assetId?.symbol}</>,
    },
    {
      text: "Price",
      dataField: "price",
      sort: true,
      formatter: (item) => <>{item.price}</>,
    },
    {
      text: "Sell Price",
      dataField: "price",
      sort: true,
      formatter: (item) => <>{item?.sellPrice || 0}</>,
    },
    {
      text: "Base Currency",
      dataField: "baseCurrency",
      sort: true,
      formatter: (item) => <>{item.currency}</>,
    }
  ];

  if (goldPermissions?.update) {
    columns.push({
      text: "Enabled",
      dataField: "isEnable",
      sort: true,
      formatter: (item) => (
        <div className="d-flex gap-3 justify-content-center">
          {
            loading 
              ? 
              <React.Fragment>
                <Spinner className="ms-2" color="primary" />  
              </React.Fragment> 
              : 
              <React.Fragment>
                <Input
                  checked={item.isEnable}
                  type="checkbox"
                  onChange={() => dispatch(toggleProduct(item._id))}
                  switch="none"
                  id={item._id}
                />
                <Label className="me-1" htmlFor={item._id} data-on-label="" data-off-label=""></Label>
              </React.Fragment>
          }
        </div>
      )
    },
    {
      text: "Action",
      dataField: "action",
      sort: false,
      formatter: (item) => (
        <UncontrolledDropdown
        >
          <DropdownToggle
            tag="i"
            className="text-muted"
            style={{ cursor: "pointer" }}
          >
            <i
              className="mdi mdi-dots-horizontal font-size-18"
              style={{
                color: "rgb(66, 65, 65)",
              }}
            />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem
              onClick={() => setEditModalData(item)}
              href="#"
            >
              {t("Edit")}
            </DropdownItem>
            <DropdownItem
              onClick={() => dispatch(toggleProduct(item._id))}
              href="#"
            >
              {item?.isEnable ? t("Disable") : t("Enable")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    }
    );
  }
  
  return (
    <>
      <MetaTags>
        <title>Product Prices</title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <h2>Product Prices</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle className="color-primary">Products ({products?.length || 0})</CardTitle>
                  {goldPermissions?.create && <GoldAddModal clearingCounter={clearingCounter} />}
                </CardHeader>
                <CardBody>
                  {/* <div className="search-box me-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <label htmlFor="search-bar-0" className="search-label">
                        <span id="search-bar-0-label" className="sr-only">
                          Search this table
                        </span>
                        <input
                          id="search-bar-0"
                          type="text"
                          aria-labelledby="search-bar-0-label"
                          className="form-control "
                          placeholder="Search"
                        />
                      </label>
                      <i
                        onClick={() => {}}
                        className="bx bx-search-alt search-icon"
                      />
                    </div>
                  </div> */}
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover "
                      >
                        <Thead className="text-center table-light">
                          <Tr>
                            {columns.map((column, index) => (
                              <Th data-priority={index} key={index}>
                                <span className="color-primary">{column.text}</span>
                              </Th>
                            ))}
                          </Tr>
                        </Thead>
                        <Tbody className="text-center">
                          {loading ? (
                            <Tr>
                              <TableLoader colSpan={6} />
                            </Tr>
                          ) : (products.length !== 0) ? products.map((row, rowIndex) => (
                            <Tr key={rowIndex}>
                              {columns.map((column, index) => (
                                <Td key={`${rowIndex}-${index}`}>
                                  {column.formatter
                                    ? column.formatter(row, rowIndex)
                                    : row[column.dataField]}
                                </Td>
                              ))}
                            </Tr>)) : (
                            <Tr>
                              <Td colSpan={6}>
                                <div className="d-flex justify-content-center">
                                  No Data Found
                                </div>
                              </Td>
                            </Tr>
                          )}
                        </Tbody>
                      </Table>
                      <CustomPagination
                        {...productsData}
                        docs={products}
                        setSizePerPage={setSizePerPage}
                        sizePerPage={sizePerPage}
                        onChange={loadProducts}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {
            editModalData && (
              <ProductEditModal
                isOpen={editModalData ? true : false}
                toggle={removeModal}
                details={editModalData}
              />
            )
          }
        </div>
      </div>
    </>
  );
}
