import React from "react";
import { useTranslation } from "react-i18next";
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  Col,
  Modal, ModalBody, ModalHeader, Row,
} from "reactstrap";

const SwapGroupsModal = ({ show, toggle, groups, swapType }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={show} toggle={toggle} centered={true} scrollable={true}>
      <ModalHeader toggle={toggle} tag="h4">
        {t("Groups")}
      </ModalHeader>
      <ModalBody >
        <Row className="mb-3">
          <Col>
            <Table className="table table-hover text-center">
              <Thead>
                <Tr>
                  <Th>{t(swapType)}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {groups?.length > 0 ? groups?.map((group, index) => (
                  <Tr key={index}>
                    <Td>{group}</Td>
                  </Tr>
                )) :
                  <h5 className="fw-bolder text-center">No groups</h5>
                }
              </Tbody>
            </Table>
          </Col>
        </Row>
      </ModalBody>
    </Modal >
  );
};

export default SwapGroupsModal;