import {
  FETCH_FACEBOOK_FORMS_FIELDS,
  FETCH_FACEBOOK_FORMS_FIELDS_FAILED,
  FETCH_FACEBOOK_FORMS_FIELDS_SUCCESS,
  UPDATE_FACEBOOK_FORMS_FIELDS,
  UPDATE_FACEBOOK_FORMS_FIELDS_FAILED,
  UPDATE_FACEBOOK_FORMS_FIELDS_SUCCESS
} from "./actionsType";

const initialState = {
  loading: false,
  clearingCounter: 0,
  fields: {},
};

const facebookFieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FACEBOOK_FORMS_FIELDS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_FACEBOOK_FORMS_FIELDS_SUCCESS:
      state = {
        ...state,
        loading: false,
        fields: action.payload,
      };
      break;
    case FETCH_FACEBOOK_FORMS_FIELDS_FAILED:
      state = {
        ...state,
        loading: false,
      };
      break;
    case UPDATE_FACEBOOK_FORMS_FIELDS: 
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_FACEBOOK_FORMS_FIELDS_SUCCESS:
      state = {
        ...state,
        loading: false,
        clearingCounter: state.clearingCounter + 1,
      };
      break;
    case UPDATE_FACEBOOK_FORMS_FIELDS_FAILED: 
      state = {
        ...state,
        loading: false,
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};
export default facebookFieldsReducer;