import TableLoader from "components/Common/Loader";
import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import {
  Tbody,
  Thead,
  Tr,
  Table,
  Td,
  Th
} from "react-super-responsive-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from "reactstrap";
import CustomPagination from "components/Common/CustomPagination";
// import DeliveryFeesAddModal from "./DeliveryFeesAdd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ProductEditModal from "./DeliveryFeesEdit";
import { fetchDeliveryFees } from "store/actions";

export default function DeliveryFees() {

  const [editModalData, setEditModalData] = React.useState(undefined);

  const removeModal = () => setEditModalData(undefined);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const payload = useSelector((state) => state.goldReducer.deliveryFees);
  const deliveryFeesPermissions = useSelector((state) => state.Profile.deliveryFeesPermissions);
  const { records, loading, clearingCounter } = payload;
  useEffect(() => {
    dispatch(fetchDeliveryFees());
  }, []);

  useEffect(() => {
    dispatch(fetchDeliveryFees());
    if (editModalData) {
      removeModal();
    }
  }, [clearingCounter]);

  const columns = [
    {
      text: t("Title"),
      dataField: "title",
      sort: true,
    },
    {
      text: t("Currency"),
      dataField: "currency",
      sort: true,
    },
    {
      text: t("Fees"),
      dataField: "fees",
      sort: true,
    }
  ];

  if (deliveryFeesPermissions?.update) {
    columns.push(
      {
        text: "Action",
        dataField: "action",
        sort: false,
        formatter: (item) => (
          <UncontrolledDropdown
          >
            <DropdownToggle
              tag="i"
              className="text-muted"
              style={{ cursor: "pointer" }}
            >
              <i
                className="mdi mdi-dots-horizontal font-size-18"
                style={{
                  color: "rgb(66, 65, 65)",
                }}
              />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={() => setEditModalData(item)}
                href="#"
              >
                {t("Edit")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
      }
    );
  }
  
  return (
    <>
      <MetaTags>
        <title>Delivery Fees</title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <h2>Delivery Fees</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle className="color-primary">Delivery Fees ({records?.length || 0})</CardTitle>
                  {/* {deliveryFeesPermissions?.create && <DeliveryFeesAddModal clearingCounter={clearingCounter} />} */}
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover "
                      >
                        <Thead className="text-center table-light">
                          <Tr>
                            {columns.map((column, index) => (
                              <Th data-priority={index} key={index}>
                                <span className="color-primary">{column.text}</span>
                              </Th>
                            ))}
                          </Tr>
                        </Thead>
                        <Tbody className="text-center">
                          {loading ? (
                            <Tr>
                              <TableLoader colSpan={6} />
                            </Tr>
                          ) : (records.length !== 0) ? records.map((row, rowIndex) => (
                            <Tr key={rowIndex}>
                              {columns.map((column, index) => (
                                <Td key={`${rowIndex}-${index}`}>
                                  {column.formatter
                                    ? column.formatter(row, rowIndex)
                                    : row[column.dataField]}
                                </Td>
                              ))}
                            </Tr>)) : (
                            <Tr>
                              <Td colSpan={6}>
                                <div className="d-flex justify-content-center">
                                  No Data Found
                                </div>
                              </Td>
                            </Tr>
                          )}
                        </Tbody>
                      </Table>
                      <CustomPagination
                        // {...productsData}
                        docs={records}
                        onChange={()=>{}}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {
            editModalData && (
              <ProductEditModal
                isOpen={editModalData ? true : false}
                toggle={removeModal}
                details={editModalData}
              />
            )
          }
        </div>
      </div>
    </>
  );
}
