/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  fetchMasterAccountRequests,
  masterAccountRequestApprove,
  masterAccountRequestReject,
} from "store/requests/actions";
import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import { useTranslation } from "react-i18next";
import Badge from "components/Common/Badge";
import { MetaTags } from "react-meta-tags";
import formatDate from "helpers/formatDate";
import ApproveDetailsModal from "./ApproveDetailsModal";
import MasterFilter from "./MasterFilter";
import {
  deductionSchedule,
  profiles
} from "./data";

function MasterRequest(props) {
  const { t } = useTranslation();
  const columns = [
    {
      dataField: "recordId",
      text: t("Request Id"),
    },
    {
      dataField: "createdAt",
      text: t("Date"),
      formatter: (val) => formatDate(val.createdAt),
    },
    {
      dataField: "customerId",
      text: t("Client"),
      formatter: (val) => {
        return (
          <div>
            <Link
              to={{
                pathname: `/clients/${val?.customerId?._id}/profile`,
                state: { clientId: val.customerId },
              }}
            >
              <i className="no-italics fw-bold">
                {val.customerId
                  ? `${captilazeFirstLetter(
                      val.customerId.firstName
                    )} ${captilazeFirstLetter(val.customerId.lastName)}`
                  : ""}
              </i>
            </Link>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: t("Allocation Type"),
      formatter: (val) => {
        return (
          <div>
            {profiles.find((item) => item.value?.toString() === val?.content?.object?.profileType?.toString())?.label || "-" }
          </div>
        );
      }
    },
    {
      dataField: "",
      text: t("Deduction Time Schedule"),
      formatter: (val) => {
        return (
          <div>
            {deductionSchedule.find((item) => item.key?.toString() === val?.content?.object?.deductionSchedule?.toString())?.name || "-" }
          </div>
        );
      }
    },
    {
      dataField: "",
      text: t("Performance Fee"),
      formatter: (val) => {
        return (
          <div>
            {(val?.content?.object?.feeSetting?.performanceFee) || "-" }
          </div>
        );
      }
    },
    {
      dataField: "",
      text: t("Management Fee"),
      formatter: (val) => {
        return (
          <div>
            {(val?.content?.object?.feeSetting?.managementFee) || "-" }
          </div>
        );
      }
    },
    {
      dataField: "",
      text: t("Periodic Management Fee"),
      formatter: (val) => {
        return (
          <div>
            {(val?.content?.object?.feeSetting?.periodicManagementFee) || "-" }
          </div>
        );
      }
    },
    {
      dataField: "",
      text: t("Per Lot Fee"),
      formatter: (val) => {
        return (
          <div>
            {(val?.content?.object?.feeSetting?.perLotFee) || "-" }
          </div>
        );
      }
    },
    {
      dataField: "",
      text: t("Client Status"),
      formatter: (val) => {
        return (
          <div>
            {(source => {
              switch (source) {
                case "SP_LIVE": return "New";
                default : return "Existing";
            }})(val?.customer?.source)}
          </div>
        );
      }
    },
    {
      dataField: "kyc",
      text: t("KYC Status"),
      formatter: (item) => {
        if (!item?.customerId?.stages) return "Pending";
        const { kycApproved, kycRejected } = item?.customerId?.stages;
        return (<div>
          <i className={kycApproved ? "text-success" : kycRejected ? "text-danger" : "text-warning"}>
            <i
              className={`mdi font-size-20 ${kycApproved ? "mdi-check-circle" : kycRejected ? "mdi-close-thick" : "mdi-alert-decagram-outline"}`}
              id={kycApproved ? "approve-icon" : kycRejected ? "reject-icon" : "pending-icon"}
            ></i>
          </i>
        </div>);
      }
    },
    {
      dataField: "status",
      text: t("Status"),
      formatter: (user) => <Badge status={user.status} />,
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: t("Actions"),
      formatter: (item) => (
        <UncontrolledDropdown
          disabled={
            !props.requestsPermissions.actions ||
            item?.status === "APPROVED" ||
            item?.status === "REJECTED"
              ? true
              : false
          }
        >
          <DropdownToggle
            tag="i"
            className="text-muted"
            style={{ cursor: "pointer" }}
          >
            <i
              className="mdi mdi-dots-horizontal font-size-18"
              style={{
                color:
                  item?.status === "APPROVED" || item?.status === "REJECTED"
                    ? "rgb(66, 65, 65)"
                    : "lightgray",
              }}
            />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem
              onClick={() => {
                setHandleModal(item);
                // masterRequestApprove(item?._id);
              }}
              href="#"
            >
              {t("Approve")}
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                masterRequestRejected(item?._id);
              }}
              href="#"
            >
              {t("Reject")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];
  const [sizePerPage, setSizePerPage] = useState(10);

  const initFilteredValues = {
    customerId: "",
    filterDate: {
      fromDate: "",
      toDate: "",
    },
    status: "",
    requestId: "",
  };
  const [filteredValues, setFilteredValues] = useState(initFilteredValues);
  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };

  const [handleModal, setHandleModal] = useState({});
  const closeModal = () => setHandleModal(false);

  const dispatch = useDispatch();
  useEffect(() => {
    loadMasterRequest(1, sizePerPage);
  }, [
    sizePerPage,
    1,
    props.isApproveOrReject,
    filteredValues,
    props.clearingCounter,
  ]);

  const loadMasterRequest = (page, limit) => {
    dispatch(
      fetchMasterAccountRequests({
        page,
        limit,
        filteredValues,
      })
    );
  };

  // const masterRequestApprove = (id) => {
  //   dispatch(masterAccountRequestApprove(id));
  // };

  const masterRequestRejected = (id) => {
    dispatch(masterAccountRequestReject(id));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Master Request</title>
        </MetaTags>
        <div className="container-fluid">
          <h2>{t("Master Request")}</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle className="color-primary">
                    {t("Master Request")} ({props.totalDocs})
                    <FeatherIcon
                      icon="refresh-cw"
                      className="icon-lg ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        loadMasterRequest(1, sizePerPage);
                      }}
                    />
                  </CardTitle>
                  <MasterFilter
                    filterChangeHandler={filterChangeHandler}
                    filteredValues={filteredValues}
                  />
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover "
                      >
                        <Thead className="text-center table-light">
                          <Tr>
                            {columns.map((column, index) => (
                              <Th data-priority={index} key={index}>
                                <span className="color-primary">{column.text}</span>
                              </Th>
                            ))}
                          </Tr>
                        </Thead>
                        <Tbody
                          className="text-center"
                          style={{
                            fontSize: "12px",
                            textAlign: "center",
                            textTransform: "capitalize",
                          }}
                        >
                          {props.loading && <TableLoader colSpan={4} />}
                          {!props.loading &&
                            props.docs.map((row, rowIndex) => (
                              <Tr key={rowIndex}>
                                {columns.map((column, index) => (
                                  <Td key={`${rowIndex}-${index}`}>
                                    {column.formatter
                                      ? column.formatter(row, rowIndex)
                                      : row[column.dataField]}
                                  </Td>
                                ))}
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                      <CustomPagination
                        {...props}
                        setSizePerPage={setSizePerPage}
                        sizePerPage={sizePerPage}
                        onChange={loadMasterRequest}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {handleModal && <ApproveDetailsModal toggleModal={closeModal} isOpen={handleModal._id} requestId={handleModal._id}
            handler={(values) =>{
              dispatch(masterAccountRequestApprove(values));
              closeModal();
            }} title={t("Approve Request")}
            requestData={handleModal?.content?.object}
          />}
          {/* {<DeleteModal loading={props.deleteLoading} onDeleteClick={deleteRole} show={deleteModal } onCloseClick={()=>{setDeleteUserModal(false)}} />} */}
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  loading: state.requestReducer.loading || false,
  docs: state.requestReducer.docs || [],
  changeStatusLoading: state.requestReducer.changeStatusLoading,
  changeStatusLoadingIndex: state.requestReducer.changeStatusLoadingIndex,
  page: state.requestReducer.page || 1,
  totalDocs: state.requestReducer.totalDocs || 0,
  totalPages: state.requestReducer.totalPages || 0,
  hasNextPage: state.requestReducer.hasNextPage,
  hasPrevPage: state.requestReducer.hasPrevPage,
  limit: state.requestReducer.limit,
  nextPage: state.requestReducer.nextPage,
  pagingCounter: state.requestReducer.pagingCounter,
  prevPage: state.requestReducer.prevPage,
  deleteLoading: state.requestReducer.deleteLoading,
  deleteClearingCounter: state.requestReducer.deleteClearingCounter,
  requestsPermissions: state.Profile.requestsPermissions || {},
  isApproveOrReject: state.requestReducer.isApproveOrReject,
  clearingCounter: state.requestReducer.clearingCounter,
});
export default connect(mapStateToProps, null)(MasterRequest);
