import {
  call, put, takeEvery
} from "redux-saga/effects";
import {
  FETCH_FACEBOOK_CAMPAIGNS,
  LOAD_FACEBOOK_CAMPAIGNS,
} from "./actionsType";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";
import {
  fetchFacebookCampaignsFailed,
  fetchFacebookCampaignsSuccess,
  loadFacebookCampaignsFailed,
  loadFacebookCampaignsSuccess
} from "./actions";
import {
  getFacebookCampaigns,
  loadFacebookCampaigns
} from "apis/facebook-campaigns";

function* getCampaigns(params) {
  try {
    const data = yield call(getFacebookCampaigns, params);
    yield put(fetchFacebookCampaignsSuccess(data));
  } catch (error) {
    yield put(fetchFacebookCampaignsFailed(error));
  }
}

function* loadCampaigns() {
  try {
    // eslint-disable-next-line no-debugger
    // debugger;
    const data = yield call(loadFacebookCampaigns);
    yield put(showSuccessNotification("Loaded Campaigns Successfully!"));
    yield put(loadFacebookCampaignsSuccess(data));
  }
  catch (error) {
    console.log(error);
    yield put(loadFacebookCampaignsFailed(error));
    yield put(showErrorNotification("Unable to load campaigns!"));
  }

}

function* facebookCampaignSaga() {
  yield takeEvery(FETCH_FACEBOOK_CAMPAIGNS, getCampaigns);
  yield takeEvery(LOAD_FACEBOOK_CAMPAIGNS, loadCampaigns);
}

export default facebookCampaignSaga;