import {
  FETCH_PRODUCTS_START,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
  ADD_PRODUCT_START,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_ERROR,
  ADD_PRODUCT_CLEAR,
  EDIT_PRODUCT_START,
  EDIT_PRODUCT_CLEAR,
  TOGGLE_PRODUCT_START,
  TOGGLE_PRODUCT_SUCCESS,
  TOGGLE_PRODUCT_ERROR,
  EDIT_PRODUCT_IMAGE_START,
} from "./actionTypes";

export const fetchProduct = (params = {}) => {
  return {
    type: FETCH_PRODUCTS_START,
    payload: params,
  };
};
export const fetchProductSuccess = (data) => {
  return {
    type: FETCH_PRODUCTS_SUCCESS,
    payload: data,
  };
};
export const fetchProductError = (error) => {
  return {
    type: FETCH_PRODUCTS_ERROR,
    payload: { error },
  };
};

export const addProduct = (params = {}) => {
  return {
    type: ADD_PRODUCT_START,
    payload: { params },
  };
};
export const addProductSuccess = (data) => {
  return {
    type: ADD_PRODUCT_SUCCESS,
    payload: data,
  };
};
export const addProductError = (error) => {
  return {
    type: ADD_PRODUCT_ERROR,
    payload: (error && error.message) || "",
  };
};
export const addProductClear = (data) => {
  return {
    type: ADD_PRODUCT_CLEAR,
    payload: data,
  };
};

export const editProduct = (params = {}) => {
  return {
    type: EDIT_PRODUCT_START,
    payload: params,
  };
};

export const editProductImage = (params = {}) => {
  return {
    type: EDIT_PRODUCT_IMAGE_START,
    payload: params,
  };
};

export const editProductClear = (data) => {
  return {
    type: EDIT_PRODUCT_CLEAR,
    payload: data,
  };
};

export const toggleProduct = (params = {}) => {
  return {
    type: TOGGLE_PRODUCT_START,
    payload: params,
  };
};

export const toggleProductSuccess = (data) => {
  return {
    type: TOGGLE_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const toggleProductError = (error) => {
  return {
    type: TOGGLE_PRODUCT_ERROR,
    payload: error,
  };
};