/* eslint-disable object-property-newline */
import { AvField, AvForm } from "availity-reactstrap-validation";
import AvFieldSelect from "components/Common/AvFieldSelect";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { fetchAccountTypes } from "store/actions";
import {
  currency,
  deductionSchedule,
  platform,
  profiles
} from "./data";
function ApproveDetailsModal({
  isOpen, toggleModal, requestId, customerId, isDisabled = false, handler = () => {}, title,
  requestData = {
    deductionSchedule: 1,
    profileType: 1,
    feeSetting: {
      performanceFee: 0,
      managementFee: 0,
      periodicManagementFee: 0,
      perLotFee: 0,
    }
  }
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { accountTypes, loading: typeLoading } = useSelector(state => state.tradingAccountReducer);

  const handleSubmit = (values) => {
    handler({
      requestId,
      customerId,
      platform: "MT5",
      currency: "USD",
      ...values,
    });
  };

  // fetch crm account types only on creating new trading account
  useEffect(() => {
    dispatch(fetchAccountTypes({
      forCrm: true
    }));
  }, []);


  const filteredOptions = accountTypes?.filter((at) => at.type === "MAM_MASTER" && at.platform === "MT5").map((item) => ({
    label: `${item.platform} | ${item.title}`,
    value: item._id,
  }));

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} centered={true} toggle={toggleModal}>
        <AvForm className="px-3"
          onValidSubmit={(e, v) => handleSubmit(v)}
        >
          <ModalHeader>{t(title)}</ModalHeader>
          <ModalBody>
            <Row>
              <Col className="py-2">
                <AvField
                  name="username"
                  label={t("Username")}
                  type="text"
                  placeholder={t("Username")}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter a valid username",
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9]+(?:[_-]?[a-zA-Z0-9])*$/,
                      errorMessage: "Please enter a valid username",
                    },
                  }}
                  {...requestData.username && {
                    value: requestData.username
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col className="py-2">
                <label>{t("Profile Type")}</label>
                <AvFieldSelect
                  name="profileType"
                  type="text"
                  options={profiles}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please select a profile type",
                    },
                  }}
                  {...requestData.profileType && {
                    value: requestData.profileType
                  }}
                />
              </Col>
              <Col className="py-2">
                <label>{t("Deduction Schedule Type")}</label>
                <AvFieldSelect
                  name="deductionSchedule"
                  type="text"
                  options={deductionSchedule.map((item) => ({
                    label: item.name,
                    value: item.key,
                  }))}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please select a schedule type",
                    },
                  }}
                  {...requestData.deductionSchedule && {
                    value: requestData.deductionSchedule
                  }}
                />
              </Col>
            </Row>
            <Row>
              {/* <Col className="py-2">
                <label>{t("Currency")}</label>
                <AvFieldSelect
                  name="currency"
                  type="text"
                  options={Object.values(currency).map((obj) => ({
                    label: obj.name,
                    value: obj.name,
                  }))}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please select a currency",
                    },
                  }}
                  isDisabled
                  value={"USD"}
                />
              </Col> */}
              {/* <Col className="py-2">
                <label>{t("Platform")}</label>
                <AvFieldSelect
                  name="platform"
                  type="text"
                  options={platform.map((item) => ({
                    label: item.name,
                    value: item.name,
                  }))}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please select a platform type",
                    },
                  }}
                  isDisabled
                  value={"MT5"}
                />
              </Col> */}
            </Row>
            {
              typeLoading ? <Spinner /> : 
                <>
                  <Row>
                    <Col className="py-2">
                      <label>{t("Master Account Type")}</label>
                      <AvFieldSelect
                        name="masterAccountType"
                        type="text"
                        options={filteredOptions}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please select a account type",
                          },
                        }}
                      />
                    </Col>
                  </Row>
                </>
            }
            <Row>
              <Col className="py-2">
                <AvField
                  name="feeSetting.performanceFee"
                  label={t("Performance Fee - %")}
                  type="number"
                  validate={{
                    min: { value: 0, errorMessage: "Please enter a valid number" },
                  }}
                  {...requestData.feeSetting?.performanceFee && {
                    value: requestData.feeSetting?.performanceFee
                  }}
                />
              </Col>
              <Col className="py-2">
                <AvField
                  name="feeSetting.managementFee"
                  label={t("Management Fee - $")}
                  type="number"
                  validate={{
                    min: { value: 0, errorMessage: "Please enter a valid number" },
                  }}
                  {...requestData.feeSetting?.managementFee && {
                    value: requestData.feeSetting?.managementFee
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col className="py-2">
                <AvField
                  name="feeSetting.periodicManagementFee"
                  label={t("Periodic Management Fee - $")}
                  type="number"
                  validate={{
                    min: { value: 0, errorMessage: "Please enter a valid number" },
                  }}
                  {...requestData.feeSetting?.periodicManagementFee && {
                    value: requestData.feeSetting?.periodicManagementFee
                  }}
                />
              </Col>
              <Col className="py-2">
                <AvField
                  name="feeSetting.perLotFee"
                  label={t("Per Lot Fee - $")}
                  type="number"
                  validate={{
                    min: { value: 0, errorMessage: "Please enter a valid number" },
                  }}
                  {...requestData.feeSetting?.perLotFee && {
                    value: requestData.feeSetting?.perLotFee
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col className="py-2">
                <AvField
                  name="feeSetting.minBalance"
                  label={t("Min Balance Required - $")}
                  type="number"
                  validate={{
                    min: { value: 0, errorMessage: "Please enter a valid number" },
                  }}
                  {...requestData.feeSetting?.minBalance && {
                    value: requestData.feeSetting?.minBalance
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleModal} color="danger">{t("Close")}</Button>
            <Button color="primary" type="submit"
              disabled={typeLoading || isDisabled}
            >
              {t("Submit")}
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
}

export default ApproveDetailsModal;
