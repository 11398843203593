import {
  FETCH_FACEBOOK_CAMPAIGNS, FETCH_FACEBOOK_CAMPAIGNS_FAILED, FETCH_FACEBOOK_CAMPAIGNS_SUCCESS, LOAD_FACEBOOK_CAMPAIGNS, LOAD_FACEBOOK_CAMPAIGNS_FAILED, LOAD_FACEBOOK_CAMPAIGNS_SUCCESS
} from "./actionsType";

export const fetchFacebookCampaignsStart = (params) => {
  return {
    type: FETCH_FACEBOOK_CAMPAIGNS,
    payload: params
  };
};

export const fetchFacebookCampaignsSuccess = (result) => {
  return {
    type: FETCH_FACEBOOK_CAMPAIGNS_SUCCESS,
    payload: result
  };
};

export const fetchFacebookCampaignsFailed = (error) => {
  return {
    type: FETCH_FACEBOOK_CAMPAIGNS_FAILED,
    payload: error
  };
};

export const loadFacebookCampaignsStart = (params) => {
  return {
    type: LOAD_FACEBOOK_CAMPAIGNS,
    payload: params
  };
};

export const loadFacebookCampaignsSuccess = (result) => {
  return {
    type: LOAD_FACEBOOK_CAMPAIGNS_SUCCESS,
    payload: result
  };
};

export const loadFacebookCampaignsFailed = (error) => {
  return {
    type: LOAD_FACEBOOK_CAMPAIGNS_FAILED,
    payload: error
  };
};