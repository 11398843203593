import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import AsyncAvFieldSelect from "./AsyncAvFieldSelect";

const SearchableCountriesDropdown = (props) => {
  const {
    placeholder = "Search",
    isRequired = false,
    name = "countries",
    isDisabled = false,
    value = null,
    isMulti = true,
    countries = [],
    onChange = () => {},
    setSelectedCountries = () => {},
    selectedCountries
  } = props;

  const countriesOptions = countries.map((country) => ({
    value: country.en,
    label: country.en,
  }));
  const selectedCountriesOptions = selectedCountries.map((country) => ({
    value: country,
    label: country
  }));

  const loadOptions = useCallback(
    (inputValue, callback) => {
      if (!inputValue) {
        callback(countriesOptions);
        return;
      }

      const filteredOptions = countriesOptions.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );

      callback(filteredOptions);
    },
    [countriesOptions]
  );

  const handleCountryChange = (selectedOption) => {
    const selectedValues = isMulti
      ? selectedOption.map(option => option.value)
      : selectedOption ? [selectedOption.value] : [];
    
    setSelectedCountries(selectedValues);
    onChange(selectedOption);
  };

  return (
    <div>
      <AsyncAvFieldSelect
        name={name}
        label={props.t("Countries")}
        errorMessage={props.t("Countries is required")}
        options={selectedCountries}
        defaultOptions={countriesOptions}
        loadOptions={loadOptions}
        isRequired={isRequired}
        placeholder={placeholder}
        value={selectedCountriesOptions}
        isSearchable={true}
        backspaceRemovesValue={true}
        onChange={handleCountryChange}
        isMulti={isMulti}
        isDisabled={isDisabled}
        disabled={isDisabled}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  countries: state.dictionaryReducer.countries || [],
});

export default connect(mapStateToProps, null)(withTranslation()(SearchableCountriesDropdown));