import React, { useEffect, useState } from "react";
import {
  useDispatch, useSelector
} from "react-redux";
import {
  Link,
  useLocation,
  useParams
} from "react-router-dom";
import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  Row, Col, Card, CardBody, CardTitle, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
import { useTranslation } from "react-i18next";

import { captilazeFirstLetter as capitalizeFirstLetter } from "common/utils/manipulateString";
import moment from "moment";
import {
  fetchProduct,
  fetchProductRequests,
  updateProductRequest
} from "store/actions";
import Badge from "components/Common/Badge";
import AdvancedFilter from "./AdvancedFilter";
import { fetchAssetsStart } from "store/assests/actions";
import { getProductRequest } from "./helper";
import ResellModal from "./ResellModal";
import FeatherIcon from "feather-icons-react";

function ProductRequest() {
  const requestType = useParams().type;
  const { t } = useTranslation();
  const { requests, clearingCounter, requestsPagination, loading: requestsLoading } = useSelector((state) => state.goldReducer.requests);
  const invalidStatus = ["REJECT", "APPROVE", "DELIVERED"];
  const allOptions = (requestType !== "delivery") ? ["IN_PROGRESS", "APPROVE", "REJECT"] : ["IN_PROGRESS", "DELIVERED", "REJECT"];
  // get query parameters from url
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const initFilteredValues =  {
    customerId: "",
    filterDate: {
      fromDate: "",
      toDate: ""
    },
    status: query.get("status") || "",
    currency: "",
    product: "",
    ...query,
  };

  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };
  const [filteredValues, setFilteredValues] = useState(initFilteredValues);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const renderOptions = (status, id, currentStatus, transaction) =>  (
    <DropdownItem
      style={{
        color: currentStatus === status ? "grey" : "black",
      }}
      key={status}
      disabled={currentStatus === status}
      onClick={() => (requestType !== "sell-back") ? dispatch(updateProductRequest(
        { 
          id, 
          status 
        }
      )) : status === "APPROVE" ? setSelectedRequest(transaction) : dispatch(updateProductRequest(
        {
          id,
          status
        }
      ))}
    >
      {status}
    </DropdownItem>
  );
  let columns = [
    {
      dataField: "recordId",
      text: t("Request Id"),
    },
    {
      dataField: "createdAt",
      text: t("Date"),
      formatter: (val) => <>{moment(val.createdAt).format("DD/MM/YYYY HH:mm:ss a")}</>,
    },
    {
      dataField: "customerId",
      text: t("Client"),
      formatter: (val) => {
        return (
          <div>
            <Link
              to={{
                pathname: `/clients/${val?.customer?._id}/profile`,
                state: { clientId: val.customer },
              }}
            >
              <i className="no-italics fw-bold">
                {val.customer
                  ? `${capitalizeFirstLetter(
                    val?.customer?.firstName
                  )} ${capitalizeFirstLetter(val?.customer?.lastName)}`
                  : ""}
              </i>
            </Link>
          </div>
        );
      },
    },
    {
      dataField: "productId",
      text: t("Product"),
      formatter: (val) => {
        return (
          <div>
            {val?.products?.title}
          </div>
        );
      }
    },
    {
      dataField: "currency",
      text: (requestType === "sell-back") ? t("Wallet Currency") : t("Currency Used"),
    },
    {
      dataField: "qty",
      text: t("Quantity"),
      formatter: (val) => <>{val?.quantity}</>
    },
    {
      dataField: "status",
      text: t("Status"),
      formatter: (val) => <Badge status={val?.status}>{val?.status}</Badge>,
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: t("Update Status"),
      formatter: (item) => (
        <UncontrolledDropdown
          disabled={
            invalidStatus.includes(item.status)
          }
        >
          <DropdownToggle
            tag="i"
            className=""
            style={{ cursor: "pointer" }}
          >
            <i
              className="mdi mdi-dots-horizontal font-size-18"
              style={{
                color: invalidStatus.includes(item.status) ? "black" : "lightgray", 
              }}
            />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {allOptions.map((option) => renderOptions(option, item._id, item.status, item))}
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];
  if (requestType === "delivery") {
    columns.splice(4, 0, {
      dataField: "type",
      text: t("Order Type"),
      formatter: (val) => val?.type,
    });
    columns.splice(5, 0, {
      dataField: "fees",
      text: t("Delivery Fees"),
      formatter: (val) => <>{parseFloat(val?.deliveryDetails.deliveryFees?.$numberDecimal || 0)?.toFixed(2)}</>,
    });
  }
  if (requestType !== "sell-back") {
    columns.splice(6, 0, {
      dataField: "amount",
      text: t("Amount"),
      formatter: (val) => <>{
        val?.deliveryDetails.deliveryFees?.$numberDecimal ? 
          parseFloat((val?.amount?.$numberDecimal - val?.deliveryDetails.deliveryFees?.$numberDecimal))?.toFixed(2) :
          parseFloat(val?.amount?.$numberDecimal || 0)?.toFixed(2)
      }</>,
    });
  }

  const [sizePerPage, setSizePerPage] = useState(10);
  const dispatch = useDispatch();

  useEffect(() => {
    
    loadRequests(1, sizePerPage);
  }, [sizePerPage, clearingCounter, requestType, filteredValues]);

  const loadRequests = (page, limit) =>{
    dispatch(fetchProductRequests({
      page,
      limit,
      type : getProductRequest(requestType),
      filteredValues
    }));
  };

  useEffect(()=>{
    loadAssests();
    loadProducts();
  }, []);
  
  const loadAssests = () => {
    dispatch(fetchAssetsStart({
      page: 1,
      limit: 100,
      isInventory: false,
      isCrypto:false,
    })) ;
  };
  const loadProducts = () => {
    dispatch(fetchProduct({
      page: 1,
      limit: 100,
    })) ;
  };
  const { assets, loading: assetLoading } = useSelector((state) => state.assetReducer);
  const { products, loading: productsLoading } = useSelector((state) => state.goldReducer.products);

  const loading = (assetLoading || productsLoading || requestsLoading);
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <h2>{capitalizeFirstLetter(requestType)} Requests</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle className="color-primary">{capitalizeFirstLetter(requestType)} Requests ({requestsPagination?.totalDocs || 0})
                    <FeatherIcon
                      icon="refresh-cw"
                      className="icon-lg ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => { loadRequests(1, sizePerPage) }}
                    />
                  </CardTitle>
                  
                  <AdvancedFilter
                    filterChangeHandler={filterChangeHandler}
                    filteredValues={filteredValues}
                    status={allOptions}
                    currencies={assets}
                    products={products}
                  />
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover "
                      >
                        <Thead className="text-center table-light" >
                          <Tr>
                            {columns.map((column, index) =>
                              <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                            )}
                          </Tr>
                        </Thead>
                        <Tbody className="text-center" style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize"
                        }}>
                          {loading && <TableLoader colSpan={4} />}
                          {!loading &&
                            requests?.length === 0  ? (
                              <>
                                <Tr>
                                  <Td colSpan={"100%"} className="fw-bolder text-center" st>
                                    <h4 className="fw-bolder text-center">No records</h4>
                                  </Td>
                                </Tr>
                              </>
                            ) :
                            !loading && requests?.map((row, rowIndex) =>
                              <Tr key={rowIndex}>
                                {columns.map((column, index) =>
                                  <Td key={`${rowIndex}-${index}`}>
                                    { column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                  </Td>
                                )}
                              </Tr>
                            )}
                        </Tbody>
                      </Table>
                      <CustomPagination
                        {...requestsPagination}
                        setSizePerPage={setSizePerPage}
                        sizePerPage={sizePerPage}
                        onChange={loadRequests}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {
        selectedRequest && (
          <ResellModal
            isOpen={selectedRequest !== null}
            toggle={() => setSelectedRequest(null)}
            request={selectedRequest}
          >
          </ResellModal>
        )
      }
    </React.Fragment>
  );
}

export default ProductRequest;
