import React from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Row,
  Col,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { swapRequestReject } from "store/requests/actions";

export default function RejectionModal({
  show, toggle, swapRequest
}) {
  const dispatch = useDispatch();
  
  const rejectRequest = (e, v) => {
    console.log(v);
    dispatch(swapRequestReject({
      ...swapRequest, 
      reason: v.reason
    }));
    toggle();
  };
  return (
    <Modal isOpen={show} toggle={toggle} centered={true} size={"md"}>
      <ModalHeader toggle={toggle} tag="h4">
            Reject Request
      </ModalHeader>
      <ModalBody >
        <AvForm
          className='p-4'
          onValidSubmit={(e, v) => {
            rejectRequest(e, v);
          }}
        >
          <Row className="mb-3">
            <Col sm={6}>
              <label>Rejection Reason</label>

            </Col>
            <Col sm={6}>
              <AvField
                name="reason"
                placeholder="Enter Rejection Reason"
                type="text"
                errorMessage="Enter Rejection Reason"
                validate={{ required: { value: true } }}
              />
            </Col>
          </Row>
          <div className='text-center pt-3 p-2'>
            <Button disabled={false} type="submit" color="primary" className="w-md">
                Update
            </Button>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}