import React from "react";
import Select from "react-select";
import { connect, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

function ItemsDropDown(props) {
  let selectedElement = Object.keys(props.options) && props.options.find((obj) => (
    obj.value == props.defaultValue
  ));
  const { layoutMode } = useSelector(state => state.Layout);

  const customStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#495057",
      padding: 0,
      paddingRight: "5px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none"
    }),
    control: (provided) => {
      if (layoutMode === "dark") {
        return {
          ...provided,
          backgroundColor: "#19283B",
          border: 0,
          boxShadow: "0 0.125rem 0.25rem #0B182F",
          color: "#adb5bd",
          height: "100%",
        };
      }
      return {
        ...provided,
        height: "100%",
      };
    },
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutMode === "dark" ? "#242632" : "white",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      zIndex: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      display: state.isDisabled ? "none" : "flex",
      flexDirection: "row",
      alignItems: "center",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
    }),
    singleValue: (provided) => {
      return {
        ...provided,
        flexDirection: "row",
        alignItems: "center",
        color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      };
    },
  };
  return (
    <React.Fragment>
      <div className="mb-3">
        <label htmlFor="choices-single-default" className="form-label font-size-14">{props.t(props.lable || "Lable")}</label>
        {
          !props.defaultValue &&
          <Select
            onChange={(e) => {
              props.elementChangeHandler(e);
            }}
            defaultValue={""}
            options={props.options}
            classNamePrefix="select2-selection"
            placeholder={props.t("All")}
            styles={customStyles}
          />
        }
        {
          props.defaultValue &&
          <Select
            onChange={(e) => {
              props.elementChangeHandler(e);
            }}
            defaultValue={selectedElement}
            options={props.options}
            style={customStyles}
            classNamePrefix="select2-selection"
          />
        }
      </div>
    </React.Fragment>);
}


export default (withTranslation()(ItemsDropDown));