import {
  FETCH_FACEBOOK_CAMPAIGNS,
  FETCH_FACEBOOK_CAMPAIGNS_FAILED,
  FETCH_FACEBOOK_CAMPAIGNS_SUCCESS,
  LOAD_FACEBOOK_CAMPAIGNS,
  LOAD_FACEBOOK_CAMPAIGNS_FAILED,
  LOAD_FACEBOOK_CAMPAIGNS_SUCCESS,
} from "./actionsType";

const initialState = {
  loading: false,
  facebookFetching: true,
  docs: [],
  clearingCounter: 0,
  pagination: {},
};
const facebookCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FACEBOOK_CAMPAIGNS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_FACEBOOK_CAMPAIGNS_SUCCESS:
      state = {
        ...state,
        loading: false,
        docs: action.payload.docs,
        pagination: {
          ...action.payload.pagination,
          ...action.payload,
        }
      };
      break;
    case FETCH_FACEBOOK_CAMPAIGNS_FAILED:
      state = {
        ...state,
        loading: false,
      };
      break;
    case LOAD_FACEBOOK_CAMPAIGNS: 
      state = {
        ...state,
        facebookFetching: true,
      };
      break;
    case LOAD_FACEBOOK_CAMPAIGNS_SUCCESS:
      state = {
        ...state,
        facebookFetching: false,
      };
      break;
    case LOAD_FACEBOOK_CAMPAIGNS_FAILED: 
      state = {
        ...state,
        facebookFetching: false,
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};
export default facebookCampaignReducer;