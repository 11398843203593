import React, {
  useCallback, useEffect, useState
} from "react";
import { debounce } from "lodash";
import { withTranslation } from "react-i18next";
import AsyncAvFieldSelect from "components/Common/AsyncAvFieldSelect";
import { getFacebookCampaigns } from "apis/facebook-campaigns";

const SearchableCampaignSelect = (props) => {
  const {
    placeholder = "Search",
    isRequired = false,
    name = "campaign",
    label = "Campaign",
    defaultOptions = [],
    value = null,
    onChange = () => { },
    disallowed = null,
  } = props;

  const [selectOptions, setSelectOptions] = useState(defaultOptions);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 100,
    };
    getFacebookCampaigns({ payload }).then((data) => {
      setSelectOptions(data?.docs?.map((campaign) => ({
        value: campaign,
        label: `${campaign.campaignName} | Status:  ${campaign.status}`
      })));
    });
  }, []);

  const debouncedChangeHandler = useCallback(
    debounce((inputValue, cb) => {
      getFacebookCampaigns({
        payload: {
          searchText: inputValue,
          page: 1,
          limit: 10,
        }
      }).then((data) => {
        return cb(data?.docs.map((campaign) => (
          {
            value: campaign,
            label: `${campaign.campaignName} | Status:  ${campaign.status}`
          }
        )));
      });
    }, 1000), []);
  return (
    <AsyncAvFieldSelect
      name={name}
      options={selectOptions}
      label={props.t(label)}
      errorMessage={props.t("Campaign is required")}
      loadOptions={debouncedChangeHandler}
      defaultOptions={selectOptions || defaultOptions}
      value={value || ""}
      defaultValue={value || ""}
      isRequired={isRequired}
      placeholder={placeholder}
      isSearchable={true}
      backspaceRemovesValue={true}
      onChange={onChange}
    />
  );
};

export default (withTranslation()(SearchableCampaignSelect));