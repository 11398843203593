import React, { useEffect, useState } from "react";
import {
  useDispatch, connect
} from "react-redux";

import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { getSalesAgentsStart } from "store/users/actions";

import ReportForm from "./ReportForm";
import TableLoader from "components/Common/TableLoader";
import formatDate from "helpers/formatDate";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { Spinner } from "reactstrap";

function getColumns (reportType) {
  switch (reportType) {
    case "client-deposits":
    case "client-withdrawals":
      return [
        {
          dataField: "name",
          text: "Client Name",
          formatter: (val) => val?.firstName ? <Link to={`/clients/${val?._id}/profile`}>{`${val?.firstName} ${val?.lastName}`}</Link> || "-" : "-"
        },
        {
          dataField: "agent",
          text: "Agent",
          formatter: (val) => (val?.agent ? `${val.agent.firstName} ${val.agent.lastName}` : "-")
        },
        {
          dataField: "fxSum",
          text: "Fx Deposit",
          formatter: (val) => val?.fxSum?.$numberDecimal ?? val?.fxSum ?? "-"
        },
        {
          dataField: "walletSum",
          text: "Wallet Deposit",
          formatter: (val) => val?.walletSum?.$numberDecimal ?? val?.walletSum ?? "-"
        },
        {
          dataField: "total",
          text: "Total Deposit",
          formatter: (val) => val?.total?.$numberDecimal ?? val?.total ?? "-"
        },
      ];
    case "withdrawals":
    case "deposits":
      return [
        {
          dataField: "customerName",
          text: "Client Name",
          formatter: (val) => val?.customer ? <Link to={`/clients/${val?.customer?._id}/profile`}>{`${val.customer.firstName} ${val.customer.lastName}`}</Link> || "-" : "-"
        },
        {
          dataField: "login",
          text: "Login",
        },
        {
          dataField: "amount",
          text: "Amount",
        },
        {
          dataField: "agent",
          text: "Agent",
          formatter: (val) => (val.agent ? `${val.agent.firstName} ${val.agent.lastName}` : "-")
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)

        }
      ];
    case "commission":
      return [
        {
          dataField: "customerName",
          text: "Client Name",
          formatter: (val) => val?.customer ? <Link to={`/clients/${val?.customer?._id}/profile`}>{`${val.customer.firstName} ${val.customer.lastName}`}</Link> || "-" : "-"
        },
        {
          dataField: "login",
          text: "Login",
        },
        {
          dataField: "profit",
          text: "Amount",
        },
        {
          dataField: "action",
          text: "Action",
          formatter: (val) => (val.action === 10 ? "Rebate" : "Commission")
        },
        {
          dataField: "clientDealId",
          text: "Client Deal",
        },
        {
          dataField: "clientLogin",
          text: "Client Login",
        },
        {
          dataField: "clientVolume",
          text: "Client Volume",
          formatter: (val) => (val.action === 10 ? val.clientVolumeClosed / 10000 : val.clientVolume / 10000)
        },
        {
          dataField: "agent",
          text: "Agent",
          formatter: (val) => (val.agent ? `${val.agent.firstName} ${val.agent.lastName}` : "-")
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)

        }
      ];
    case "summary":
      return [
        {
          dataField: "login",
          text: "Login",
        },
        {
          dataField: "dealId",
          text: "Deal Id",
        },
        {
          dataField: "positionId",
          text: "Position Id",
        },
        {
          dataField: "price",
          text: "Price",
        },
        {
          dataField: "volumeClosed",
          text: "Volume",
        },
        {
          dataField: "profit",
          text: "Amount",
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)

        }
      ];
    case "lead-converted":
      return [
        {
          dataField: "name",
          text: "Name",
          formatter: (val) => (`${val.firstName} ${val.lastName}`)
        },
        {
          dataField: "agent",
          text: "Agent",
          formatter: (val) => (val.agent ? `${val.agent.firstName} ${val.agent.lastName}` : "")
        },
        {
          dataField: "convertTime",
          text: "Convert TIme",
          formatter: (val) => formatDate(val?.fx?.demoConvertTime)
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)
        },
      ];
    case "lead-call-status":
      return [
        {
          dataField: "name",
          text: "Name",
          formatter: (val) => (`${val.firstName} ${val.lastName}`)
        },
        {
          dataField: "agent",
          text: "Agent",
          formatter: (val) => (val.agent ? `${val.agent.firstName} ${val.agent.lastName}` : "")
        },
        {
          dataField: "callStatus",
          text: "Call Status",
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)
        },
      ];
    case "last-login":
      return [
        {
          dataField: "name",
          text: "Name",
          formatter: (val) => (`${val.firstName} ${val.lastName}`)
        },
        {
          dataField: "agent",
          text: "Agent",
          formatter: (val) => (val.agent ? `${val.agent.firstName} ${val.agent.lastName}` : "-")
        },
        {
          dataField: "lastLogin",
          text: "Last Login",
          formatter: (val) => formatDate(val.lastLogin)
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)
        },
      ];
    case "unfunded-accounts":
      return [
        {
          dataField: "login",
          text: "Login",
          formatter: (val) => <>
            {val?.login}
          </>
        },
        {
          dataField: "clientName",
          text: "Client Name",
          formatter: (val) => <Link to={`clients/${val?.customer?._id}/profile`}>
            {val?.customer ? `${val?.customer?.firstName} ${val?.customer?.lastName}` : ""}
          </Link>
        },
        {
          dataField: "currency",
          text: "Currency",
        },
        {
          dataField: "agent",
          text: "Agent",
          formatter: (val) => (val?.agent?.length ? `${val.agent?.[0]?.firstName} ${val.agent?.[0]?.lastName}` : "-")
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)
        }
      ];
    case "credit-in":
    case "credit-out":
      return [
        {
          dataField: "login",
          text: "Login",
          formatter: (val) => <>
            {val?.login ? val?.login : ""}
          </>
        },
        {
          dataField: "clientName",
          text: "Client Name",
          formatter: (val) => <Link to={`clients/${val?.customer?._id}/profile`}>
            {val?.customer ? `${val?.customer?.firstName} ${val?.customer?.lastName}` : ""}
          </Link>
        },
        {
          dataField: "amount",
          text: "Amount",
        },
        {
          dataField: "agent",
          text: "Agent",
          formatter: (val) => (val?.agent ? `${val.agent?.firstName} ${val.agent?.lastName}` : "-")
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)
        }
      ];
    default: 
      return [
        {
          dataField: "login",
          text: "Login",
        },
        {
          dataField: "profit",
          text: "Amount",
        },
        {
          dataField: "comment",
          text: "Comment",
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)
        }
      ];
  }
}

function ShowStats (props) {
  const { type, dateFrom, dateTo, agent } = props;
  if (!type) return <></>;
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({});

  useEffect(() => {
    setLoading(true);
    if (type) {
      (async () => {
        axios.get(`http://localhost:3001/api/v1/crm/reports/stats/${type}`, {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("authUser"))?.token}`
          },
          params: {
            dateFrom,
            dateTo,
            agent
          },
        })
          .then(res => {
            setStats(res.data?.result?.[0]);
            setLoading(false);
          })
          .catch(err => {
            setLoading(false);
            // eslint-disable-next-line no-console
            console.log(err);
          });
      })();
    }
  }, [type, dateFrom, dateTo, agent]);

  if (!Object.keys((stats || {})).length) return <></>;

  if (loading) return <Spinner />;

  const formatData = (data) => {
    switch (type) {
      case "deposits":
        return  `Total Deposit : ${parseFloat(data?.totalDeposit?.$numberDecimal || data?.totalDeposit || 0)?.toFixed(2)}`;
      case "withdrawals":
        return `Total Withdrawal : ${parseFloat(data?.totalWithdrawal?.$numberDecimal || data?.totalWithdrawal || 0)?.toFixed(2)}`;
      case "client-deposits":
        return `Total Deposit : ${parseFloat(data?.totalDeposit?.$numberDecimal || data?.totalDeposit || 0)?.toFixed(2)}`;
      case "client-withdrawals":
        return `Total Withdrawal : ${parseFloat(data?.totalWithdrawal?.$numberDecimal || data?.totalWithdrawal || 0)?.toFixed(2)}`;
      case "credit-in":
        return `Total Amount : ${data?.totalCreditAmount || 0}`;
      case "credit-out":
        return `Total Amount : ${data?.totalCreditAmount || 0}`;
      default: return "";
    }
  };

  return <>{formatData(stats)}</>;
}

function ReportsList(props) {
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const [columns, setColumns] = useState([]);
  const [payload, setPayload] = useState({});

  useEffect(() => {
    dispatch(getSalesAgentsStart({
      page:1,
      limit:1000
    }));
  }, []);

  const getData = (type) => {
    switch (type) {
      case "client-deposits":
        return { 
          totalDeposit: props.reportsReducer.totalDeposit || 0,
        };
      case "client-withdrawals":
        return { 
          totalWithdrawal: props.reportsReducer.totalWithdrawal || 0,
        };
      default:
        props.reportsReducer.stats;
    }
  };

  return (
    <React.Fragment>
      <ReportForm setReportColumns={(type)=>{{
        setColumns(getColumns(type));
        setType(type);
      }}} setPayload={setPayload} >                    
        <Table
          id="tech-companies-1"
          className="table  table-hover "
        >
          <Thead className="text-center table-light" >
            <Tr>
              {columns.map((column, index) =>
                <Th data-priority={index} key={index}>
                  <span className="color-primary">{column.text}</span>
                </Th>
              )}
            </Tr>
          </Thead>
          <Tbody className="text-center" style={{
            minHeight: "calc(100vh - 200px)"
          }}>
            {props.loading && <TableLoader colSpan={4} />}
            {!props.loading && props.docs.length === 0 && 
              <>
                <Tr>
                  <Td colSpan={"100%"} className="fw-bolder text-center" >
                    <h3 className="fw-bolder text-center">No records</h3>
                  </Td>
                </Tr>
              </>
            }
            {!props.loading && props.docs.map((row, rowIndex) =>
              <Tr key={rowIndex}>
                {columns.map((column, index) =>
                  <Td key={`${rowIndex}-${index}`}>
                    { column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                  </Td>
                )}
              </Tr>
            )}
            <Tr>
              <Td colSpan={columns.length}>
                <ShowStats type={payload?.type} dateFrom={payload?.dateFrom} dateTo={payload?.dateTo} agent={payload?.agent} />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </ReportForm>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  loading: state.reportsReducer.loading || false,
  docs: state.reportsReducer.docs || [],
  reportsReducer: state.reportsReducer || {},


});
export default connect(mapStateToProps, null)(ReportsList);
