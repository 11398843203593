import React from "react";
import { useTranslation } from "react-i18next";
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  Col,
  Modal, ModalBody, ModalHeader, Row,
} from "reactstrap";

const LoginsModal = ({ show, toggle, accounts }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={show} toggle={toggle} centered={true} scrollable={true}>
      <ModalHeader toggle={toggle} tag="h4">
        {t("Accounts")}
      </ModalHeader>
      <ModalBody>
        <Row className="mb-3">
          <Col>
            <Table className="table table-hover text-center">
              <Thead>
                <Tr>
                  <Th>{t("Accounts")}</Th>
                  <Th>{t("Account Type")}</Th>
                  <Th>{t("Account Platform")}</Th>
                  <Th>{t("Account Group")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {accounts?.map((account, index) => (
                  <Tr key={index}>
                    <Td>{account?.login || t("N/A")}</Td>
                    <Td>{account?.accountTypeId.title || t("N/A")}</Td>
                    <Td>{account?.accountTypeId.platform || t("N/A")}</Td>
                    <Td>{account?.accountTypeId.currencies?.[0].groupPath || t("N/A")}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default LoginsModal;