
import React, { useRef } from "react";
import {
  AvField
} from "availity-reactstrap-validation";
import Select from "react-select";
import { useSelector } from "react-redux";

function AvFieldSelecvt(props) {
  const ref1 = useRef();
  const [state, setState] = React.useState(null);
  const onFieldChange = (e) => {
    setState(e);
  };
  const { label, ...params } = props;
  let options = props.options || [];
  if (props.placeholder) {
    options = [
      {
        label: props.placeholder,
        value: "",
      },
      ...options
    ];
  }
  let defArr = props.value || [];
  let defaultValue = options.filter(obj => defArr.indexOf(obj.value) > -1);

  const { layoutMode } = useSelector(state => state.Layout);

  const customStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#495057",
      padding: 0,
      paddingRight: "5px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none"
    }),
    control: (provided) => {
      if (layoutMode === "dark") {
        return {
          ...provided,
          backgroundColor: "#19283B",
          border: 0,
          boxShadow: "0 0.125rem 0.25rem #0B182F",
          color: "#adb5bd",
          height: "100%",
        };
      }
      return {
        ...provided,
        height: "100%",
      };
    },
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutMode === "dark" ? "#242632" : "white",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      zIndex: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      display: state.isDisabled ? "none" : "flex",
      flexDirection: "row",
      alignItems: "center",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
    }),
    singleValue: (provided) => {
      return {
        ...provided,
        flexDirection: "row",
        alignItems: "center",
        color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      };
    },
  };
  return (<React.Fragment>
    <label>{label}</label>
    <Select
      ref={ref1}
      defaultValue={defaultValue}
      options={options} 
      onChange={onFieldChange}
      className="basic-multi-select"
      isMulti
      styles={customStyles}
      classNamePrefix="select"
    />
    <AvField
      {...params}
      type="select"
      value={state === null ? defaultValue : state}
      style={{
        opacity: 0,
        height: 0,
        margin: -10,
        "pointerEvents": "none",
      }}
    >
      {options.map((obj, index) => {
        return (<option key={index} value={obj.value}>{obj.label}</option>);
      })}
    </AvField>
    

  </React.Fragment>);
}

export default AvFieldSelecvt;
