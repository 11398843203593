import qs from "qs";
import * as axiosHelper from "./api_helper";

export const getFacebookCampaigns = async ({ payload }) => {
  const data = await axiosHelper.get(`/facebook-campaigns?${qs.stringify(payload)}`);
  return data.result;
};

export const loadFacebookCampaigns = async () => {
  console.log("test");
  const data = await axiosHelper.post("/facebook-campaigns");
  return data;
};

export const loadFacebookFormsFields = async () => {
  const data = await axiosHelper.get("/facebook-fields");
  return data?.result;
};

export const updateFacebookFormsFields = async (payload) => {
  const data = await axiosHelper.post("/facebook-fields", payload);
  if (data?.isError) {
    throw new Error(data?.message);
  }
  return data;
};