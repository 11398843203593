

import qs from "qs";
import * as axiosHelper from "./api_helper";

export const getProduct = async({ payload }) => {
  const data = await axiosHelper.get(`/gold?${qs.stringify(payload)}`);
  return data.result;
};

export const addProduct = async(payload) => {
  const data = await axiosHelper.postFormData("/gold", payload);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const editProduct = async({ payload }) => {
  const { id, values } = payload;
  const data = await axiosHelper.patch(`/gold/${id}`, values);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const editProductImage = async({ payload }) => {
  const data = await axiosHelper.postFormData("/gold/image", payload);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data;
};

export const toggleProduct = async({ id }) => {
  const data = await axiosHelper.patch(`/gold/toggle/${id}`);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const getTransactions = async({ payload }) => {
  const data = await axiosHelper.get(`/gold/transactions?${qs.stringify(payload)}`);
  return data.result;
};

export const getProductRequests = async({ params }) => {
  const data = await axiosHelper.get(`/gold/transactions?${qs.stringify(params)}`);
  return data.result;
};

export const updateProductRequest = async({ id, status, amount = 0 }) => {
  const data = await axiosHelper.patch(`/gold/transactions/${id}`,
    {
      status,
      amount
    }
  );
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};
export const getGoldTransfers = async({ payload }) => {
  const data = await axiosHelper.get(`/gold/transfer?${qs.stringify(payload)}`);
  return data.result;
};

export const approveGold = async(payload) => {
  const data = await axiosHelper.patch(`/gold/transfer/approve/${payload}`);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const rejectGold = async(payload) => {
  const data = await axiosHelper.patch(`/gold/transfer/reject/${payload}`);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const getReport = async({ payload }) => {
  const data = await axiosHelper.get(`/reports/products?${qs.stringify(payload)}`);
  return data.result;
};
