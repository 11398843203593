import React, {
  useCallback, useEffect, useState
} from "react";
import { debounce } from "lodash";
import { withTranslation } from "react-i18next";
import AsyncAvFieldSelect from "components/Common/AsyncAvFieldSelect";
import { getAssignedUsers } from "apis/users";

const SearchableCampaignSelect = (props) => {

  const {
    placeholder = "Search",
    isRequired = false,
    name = "agent",
    label = "Agent",
    defaultOptions = [],
    value = null,
    onChange = () => { },
    disallowed = null,
  } = props;

  const [selectOptions, setSelectOptions] = useState(defaultOptions);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 10,
    };
    getAssignedUsers({ payload }).then((data) => {
      setSelectOptions(data?.result?.docs?.map((client) => ({
        label: `${client.firstName} ${client.lastName}`,
        value: `${client._id}`
      })));
    });
  }, []);

  const debouncedChangeHandler = useCallback(
    debounce((inputValue, cb) => {
      getAssignedUsers({
        payload: {
          searchText: inputValue,
          page: 1,
          limit: 10,
        }
      }).then((data) => {
        return cb(data?.result?.docs.map((client) => (
          {
            label: `${client.firstName} ${client.lastName}`,
            value: `${client._id}`
          }
        )));
      });
    }, 1000), []);
  return (
    <AsyncAvFieldSelect
      name={name}
      options={selectOptions}
      label={props.t(label)}
      errorMessage={props.t("Campaign is required")}
      loadOptions={debouncedChangeHandler}
      defaultOptions={selectOptions || defaultOptions}
      value={value || ""}
      defaultValue={value || ""}
      isRequired={isRequired}
      placeholder={placeholder}
      isSearchable={true}
      backspaceRemovesValue={true}
      onChange={onChange}
      isMulti
    />
  );
};

export default (withTranslation()(SearchableCampaignSelect));