import React, {
  useCallback, useEffect, useState
} from "react";
import { debounce } from "lodash";
import { withTranslation } from "react-i18next";
import AsyncAvFieldSelect from "components/Common/AsyncAvFieldSelect";
import { getTeams } from "apis/teams";

const SearchableTeamSelect = (props) => {

  const {
    placeholder = "Search",
    isRequired = false,
    name = "team",
    label = "Team",
    defaultOptions = [],
    value = null,
    onChange = () => { },
    disallowed = null,
  } = props;

  const [selectOptions, setSelectOptions] = useState(defaultOptions);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 10,
    };
    getTeams({ payload }).then((data) => {
      setSelectOptions(data?.docs?.map((team) => ({
        label: `${team.title} | ${team.managerId?.firstName} ${team.managerId?.lastName} | ${team.members?.length} Members`,
        value: team,
      })));
    });
  }, []);

  const debouncedChangeHandler = useCallback(
    debounce((inputValue, cb) => {
      getTeams({
        payload: {
          searchText: inputValue,
          page: 1,
          limit: 10,
        }
      }).then((data) => {
        return cb(data?.docs
          .filter((item) => 
            item.title.toLowerCase().includes(inputValue.toLowerCase()) ||
            item.managerId?.firstName.toLowerCase().includes(inputValue.toLowerCase()) ||
            item.managerId?.lastName.toLowerCase().includes(inputValue.toLowerCase()) || 
            item.managerId?.email.toLowerCase().includes(inputValue.toLowerCase()) 
          )
          .map((team) => (
            {
              label: `${team.title} | ${team.managerId?.firstName} ${team.managerId?.lastName} | ${team.members?.length} Members`,
              value: team,
            }
          )));
      });
    }, 1000), []);
  return (
    <AsyncAvFieldSelect
      name={name}
      options={selectOptions}
      label={props.t(label)}
      errorMessage={props.t("Team is required")}
      loadOptions={debouncedChangeHandler}
      defaultOptions={selectOptions || defaultOptions}
      value={value || ""}
      defaultValue={value || ""}
      isRequired={isRequired}
      placeholder={placeholder}
      isSearchable={true}
      backspaceRemovesValue={true}
      onChange={onChange}
    />
  );
};

export default (withTranslation()(SearchableTeamSelect));